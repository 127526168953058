@charset "UTF-8";
/*
Custom boostrap imports from node_modules
 */
*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(19, 19, 30, 0);
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
  display: block;
}

body {
  margin: 0;
  font-family: "Benton Sans", "Libre Franklin", sans-serif;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #13131e;
  text-align: left;
  background-color: #fff;
}

[tabindex="-1"]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: underline;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: .5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub,
sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -.25em;
}

sup {
  top: -.5em;
}

a {
  color: #f5bd52;
  text-decoration: none;
  background-color: transparent;
}

a:hover {
  color: #eda00e;
  text-decoration: underline;
}

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  color: inherit;
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre,
code,
kbd,
samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
}

figure {
  margin: 0 0 1rem;
}

img {
  vertical-align: middle;
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  color: #919ba2;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0.5rem;
}

button {
  border-radius: 0;
}

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
input {
  overflow: visible;
}

button,
select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button,
[type="button"],
[type="reset"],
[type="submit"] {
  -webkit-appearance: button;
}

button:not(:disabled),
[type="button"]:not(:disabled),
[type="reset"]:not(:disabled),
[type="submit"]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner,
[type="button"]::-moz-focus-inner,
[type="reset"]::-moz-focus-inner,
[type="submit"]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type="radio"],
input[type="checkbox"] {
  box-sizing: border-box;
  padding: 0;
}

input[type="date"],
input[type="time"],
input[type="datetime-local"],
input[type="month"] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="search"] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

[hidden] {
  display: none !important;
}

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 1rem;
  font-family: "Benton Sans", "Libre Franklin", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: inherit;
}

h1, .h1 {
  font-size: 2.8125rem;
}

h2, .h2 {
  font-size: 2.1875rem;
}

h3, .h3 {
  font-size: 1.3125rem;
}

h4, .h4 {
  font-size: 1.125rem;
}

h5, .h5 {
  font-size: 1rem;
}

h6, .h6 {
  font-size: 0.875rem;
}

.lead {
  font-size: 1.5rem;
  font-weight: 400;
}

.display-1 {
  font-size: 5rem;
  font-weight: 800;
  line-height: 1;
}

.display-2 {
  font-size: 4.375rem;
  font-weight: 800;
  line-height: 1;
}

.display-3 {
  font-size: 3.75rem;
  font-weight: 800;
  line-height: 1;
}

.display-4 {
  font-size: 2.125rem;
  font-weight: 800;
  line-height: 1;
}

hr {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 0;
  border-top: 1px solid rgba(19, 19, 30, 0.1);
}

small,
.small {
  font-size: 80%;
  font-weight: 400;
}

mark,
.mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}

.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 90%;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}

.blockquote-footer {
  display: block;
  font-size: 80%;
  color: #5a6067;
}

.blockquote-footer::before {
  content: "\2014\00A0";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #afbac0;
  border-radius: 0;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 90%;
  color: #5a6067;
}

.container {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 1200px) {
  .container {
    max-width: 992px;
  }
}

.container-fluid {
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
  margin-right: auto;
  margin-left: auto;
}

.row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -0.75rem;
  margin-left: -0.75rem;
}

.no-gutters {
  margin-right: 0;
  margin-left: 0;
}

.no-gutters > .col,
.no-gutters > [class*="col-"] {
  padding-right: 0;
  padding-left: 0;
}

.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col,
.col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm,
.col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md,
.col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg,
.col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
}

.col-1 {
  flex: 0 0 8.333333333%;
  max-width: 8.333333333%;
}

.col-2 {
  flex: 0 0 16.666666667%;
  max-width: 16.666666667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.333333333%;
  max-width: 33.333333333%;
}

.col-5 {
  flex: 0 0 41.666666667%;
  max-width: 41.666666667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.333333333%;
  max-width: 58.333333333%;
}

.col-8 {
  flex: 0 0 66.666666667%;
  max-width: 66.666666667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.333333333%;
  max-width: 83.333333333%;
}

.col-11 {
  flex: 0 0 91.666666667%;
  max-width: 91.666666667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.order-first {
  order: -1;
}

.order-last {
  order: 13;
}

.order-0 {
  order: 0;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.offset-1 {
  margin-left: 8.333333333%;
}

.offset-2 {
  margin-left: 16.666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.333333333%;
}

.offset-5 {
  margin-left: 41.666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.333333333%;
}

.offset-8 {
  margin-left: 66.666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.333333333%;
}

.offset-11 {
  margin-left: 91.666666667%;
}

@media (min-width: 576px) {
  .col-sm {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-sm-1 {
    flex: 0 0 8.333333333%;
    max-width: 8.333333333%;
  }
  .col-sm-2 {
    flex: 0 0 16.666666667%;
    max-width: 16.666666667%;
  }
  .col-sm-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-sm-4 {
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
  .col-sm-5 {
    flex: 0 0 41.666666667%;
    max-width: 41.666666667%;
  }
  .col-sm-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-sm-7 {
    flex: 0 0 58.333333333%;
    max-width: 58.333333333%;
  }
  .col-sm-8 {
    flex: 0 0 66.666666667%;
    max-width: 66.666666667%;
  }
  .col-sm-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-sm-10 {
    flex: 0 0 83.333333333%;
    max-width: 83.333333333%;
  }
  .col-sm-11 {
    flex: 0 0 91.666666667%;
    max-width: 91.666666667%;
  }
  .col-sm-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-sm-first {
    order: -1;
  }
  .order-sm-last {
    order: 13;
  }
  .order-sm-0 {
    order: 0;
  }
  .order-sm-1 {
    order: 1;
  }
  .order-sm-2 {
    order: 2;
  }
  .order-sm-3 {
    order: 3;
  }
  .order-sm-4 {
    order: 4;
  }
  .order-sm-5 {
    order: 5;
  }
  .order-sm-6 {
    order: 6;
  }
  .order-sm-7 {
    order: 7;
  }
  .order-sm-8 {
    order: 8;
  }
  .order-sm-9 {
    order: 9;
  }
  .order-sm-10 {
    order: 10;
  }
  .order-sm-11 {
    order: 11;
  }
  .order-sm-12 {
    order: 12;
  }
  .offset-sm-0 {
    margin-left: 0;
  }
  .offset-sm-1 {
    margin-left: 8.333333333%;
  }
  .offset-sm-2 {
    margin-left: 16.666666667%;
  }
  .offset-sm-3 {
    margin-left: 25%;
  }
  .offset-sm-4 {
    margin-left: 33.333333333%;
  }
  .offset-sm-5 {
    margin-left: 41.666666667%;
  }
  .offset-sm-6 {
    margin-left: 50%;
  }
  .offset-sm-7 {
    margin-left: 58.333333333%;
  }
  .offset-sm-8 {
    margin-left: 66.666666667%;
  }
  .offset-sm-9 {
    margin-left: 75%;
  }
  .offset-sm-10 {
    margin-left: 83.333333333%;
  }
  .offset-sm-11 {
    margin-left: 91.666666667%;
  }
}

@media (min-width: 768px) {
  .col-md {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-md-1 {
    flex: 0 0 8.333333333%;
    max-width: 8.333333333%;
  }
  .col-md-2 {
    flex: 0 0 16.666666667%;
    max-width: 16.666666667%;
  }
  .col-md-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-md-4 {
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
  .col-md-5 {
    flex: 0 0 41.666666667%;
    max-width: 41.666666667%;
  }
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-md-7 {
    flex: 0 0 58.333333333%;
    max-width: 58.333333333%;
  }
  .col-md-8 {
    flex: 0 0 66.666666667%;
    max-width: 66.666666667%;
  }
  .col-md-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-md-10 {
    flex: 0 0 83.333333333%;
    max-width: 83.333333333%;
  }
  .col-md-11 {
    flex: 0 0 91.666666667%;
    max-width: 91.666666667%;
  }
  .col-md-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-md-first {
    order: -1;
  }
  .order-md-last {
    order: 13;
  }
  .order-md-0 {
    order: 0;
  }
  .order-md-1 {
    order: 1;
  }
  .order-md-2 {
    order: 2;
  }
  .order-md-3 {
    order: 3;
  }
  .order-md-4 {
    order: 4;
  }
  .order-md-5 {
    order: 5;
  }
  .order-md-6 {
    order: 6;
  }
  .order-md-7 {
    order: 7;
  }
  .order-md-8 {
    order: 8;
  }
  .order-md-9 {
    order: 9;
  }
  .order-md-10 {
    order: 10;
  }
  .order-md-11 {
    order: 11;
  }
  .order-md-12 {
    order: 12;
  }
  .offset-md-0 {
    margin-left: 0;
  }
  .offset-md-1 {
    margin-left: 8.333333333%;
  }
  .offset-md-2 {
    margin-left: 16.666666667%;
  }
  .offset-md-3 {
    margin-left: 25%;
  }
  .offset-md-4 {
    margin-left: 33.333333333%;
  }
  .offset-md-5 {
    margin-left: 41.666666667%;
  }
  .offset-md-6 {
    margin-left: 50%;
  }
  .offset-md-7 {
    margin-left: 58.333333333%;
  }
  .offset-md-8 {
    margin-left: 66.666666667%;
  }
  .offset-md-9 {
    margin-left: 75%;
  }
  .offset-md-10 {
    margin-left: 83.333333333%;
  }
  .offset-md-11 {
    margin-left: 91.666666667%;
  }
}

@media (min-width: 992px) {
  .col-lg {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-lg-1 {
    flex: 0 0 8.333333333%;
    max-width: 8.333333333%;
  }
  .col-lg-2 {
    flex: 0 0 16.666666667%;
    max-width: 16.666666667%;
  }
  .col-lg-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-lg-4 {
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
  .col-lg-5 {
    flex: 0 0 41.666666667%;
    max-width: 41.666666667%;
  }
  .col-lg-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-lg-7 {
    flex: 0 0 58.333333333%;
    max-width: 58.333333333%;
  }
  .col-lg-8 {
    flex: 0 0 66.666666667%;
    max-width: 66.666666667%;
  }
  .col-lg-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-lg-10 {
    flex: 0 0 83.333333333%;
    max-width: 83.333333333%;
  }
  .col-lg-11 {
    flex: 0 0 91.666666667%;
    max-width: 91.666666667%;
  }
  .col-lg-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-lg-first {
    order: -1;
  }
  .order-lg-last {
    order: 13;
  }
  .order-lg-0 {
    order: 0;
  }
  .order-lg-1 {
    order: 1;
  }
  .order-lg-2 {
    order: 2;
  }
  .order-lg-3 {
    order: 3;
  }
  .order-lg-4 {
    order: 4;
  }
  .order-lg-5 {
    order: 5;
  }
  .order-lg-6 {
    order: 6;
  }
  .order-lg-7 {
    order: 7;
  }
  .order-lg-8 {
    order: 8;
  }
  .order-lg-9 {
    order: 9;
  }
  .order-lg-10 {
    order: 10;
  }
  .order-lg-11 {
    order: 11;
  }
  .order-lg-12 {
    order: 12;
  }
  .offset-lg-0 {
    margin-left: 0;
  }
  .offset-lg-1 {
    margin-left: 8.333333333%;
  }
  .offset-lg-2 {
    margin-left: 16.666666667%;
  }
  .offset-lg-3 {
    margin-left: 25%;
  }
  .offset-lg-4 {
    margin-left: 33.333333333%;
  }
  .offset-lg-5 {
    margin-left: 41.666666667%;
  }
  .offset-lg-6 {
    margin-left: 50%;
  }
  .offset-lg-7 {
    margin-left: 58.333333333%;
  }
  .offset-lg-8 {
    margin-left: 66.666666667%;
  }
  .offset-lg-9 {
    margin-left: 75%;
  }
  .offset-lg-10 {
    margin-left: 83.333333333%;
  }
  .offset-lg-11 {
    margin-left: 91.666666667%;
  }
}

@media (min-width: 1200px) {
  .col-xl {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;
  }
  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%;
  }
  .col-xl-1 {
    flex: 0 0 8.333333333%;
    max-width: 8.333333333%;
  }
  .col-xl-2 {
    flex: 0 0 16.666666667%;
    max-width: 16.666666667%;
  }
  .col-xl-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }
  .col-xl-4 {
    flex: 0 0 33.333333333%;
    max-width: 33.333333333%;
  }
  .col-xl-5 {
    flex: 0 0 41.666666667%;
    max-width: 41.666666667%;
  }
  .col-xl-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
  .col-xl-7 {
    flex: 0 0 58.333333333%;
    max-width: 58.333333333%;
  }
  .col-xl-8 {
    flex: 0 0 66.666666667%;
    max-width: 66.666666667%;
  }
  .col-xl-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }
  .col-xl-10 {
    flex: 0 0 83.333333333%;
    max-width: 83.333333333%;
  }
  .col-xl-11 {
    flex: 0 0 91.666666667%;
    max-width: 91.666666667%;
  }
  .col-xl-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }
  .order-xl-first {
    order: -1;
  }
  .order-xl-last {
    order: 13;
  }
  .order-xl-0 {
    order: 0;
  }
  .order-xl-1 {
    order: 1;
  }
  .order-xl-2 {
    order: 2;
  }
  .order-xl-3 {
    order: 3;
  }
  .order-xl-4 {
    order: 4;
  }
  .order-xl-5 {
    order: 5;
  }
  .order-xl-6 {
    order: 6;
  }
  .order-xl-7 {
    order: 7;
  }
  .order-xl-8 {
    order: 8;
  }
  .order-xl-9 {
    order: 9;
  }
  .order-xl-10 {
    order: 10;
  }
  .order-xl-11 {
    order: 11;
  }
  .order-xl-12 {
    order: 12;
  }
  .offset-xl-0 {
    margin-left: 0;
  }
  .offset-xl-1 {
    margin-left: 8.333333333%;
  }
  .offset-xl-2 {
    margin-left: 16.666666667%;
  }
  .offset-xl-3 {
    margin-left: 25%;
  }
  .offset-xl-4 {
    margin-left: 33.333333333%;
  }
  .offset-xl-5 {
    margin-left: 41.666666667%;
  }
  .offset-xl-6 {
    margin-left: 50%;
  }
  .offset-xl-7 {
    margin-left: 58.333333333%;
  }
  .offset-xl-8 {
    margin-left: 66.666666667%;
  }
  .offset-xl-9 {
    margin-left: 75%;
  }
  .offset-xl-10 {
    margin-left: 83.333333333%;
  }
  .offset-xl-11 {
    margin-left: 91.666666667%;
  }
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #13131e;
}

.table th,
.table td {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid #d1d9dd;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #d1d9dd;
}

.table tbody + tbody {
  border-top: 2px solid #d1d9dd;
}

.table-sm th,
.table-sm td {
  padding: 0.3rem;
}

.table-bordered {
  border: 1px solid #d1d9dd;
}

.table-bordered th,
.table-bordered td {
  border: 1px solid #d1d9dd;
}

.table-bordered thead th,
.table-bordered thead td {
  border-bottom-width: 2px;
}

.table-borderless th,
.table-borderless td,
.table-borderless thead th,
.table-borderless tbody + tbody {
  border: 0;
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: #fff;
}

.table-hover tbody tr:hover {
  color: #13131e;
  background-color: rgba(19, 19, 30, 0.075);
}

.table-primary,
.table-primary > th,
.table-primary > td {
  background-color: #fcedcf;
}

.table-primary th,
.table-primary td,
.table-primary thead th,
.table-primary tbody + tbody {
  border-color: #fadda5;
}

.table-hover .table-primary:hover {
  background-color: #fbe4b7;
}

.table-hover .table-primary:hover > td,
.table-hover .table-primary:hover > th {
  background-color: #fbe4b7;
}

.table-secondary,
.table-secondary > th,
.table-secondary > td {
  background-color: #bac3cc;
}

.table-secondary th,
.table-secondary td,
.table-secondary thead th,
.table-secondary tbody + tbody {
  border-color: #7f90a0;
}

.table-hover .table-secondary:hover {
  background-color: #abb6c1;
}

.table-hover .table-secondary:hover > td,
.table-hover .table-secondary:hover > th {
  background-color: #abb6c1;
}

.table-success,
.table-success > th,
.table-success > td {
  background-color: #c3e6cb;
}

.table-success th,
.table-success td,
.table-success thead th,
.table-success tbody + tbody {
  border-color: #8fd19e;
}

.table-hover .table-success:hover {
  background-color: #b1dfbb;
}

.table-hover .table-success:hover > td,
.table-hover .table-success:hover > th {
  background-color: #b1dfbb;
}

.table-info,
.table-info > th,
.table-info > td {
  background-color: #bee5eb;
}

.table-info th,
.table-info td,
.table-info thead th,
.table-info tbody + tbody {
  border-color: #86cfda;
}

.table-hover .table-info:hover {
  background-color: #abdde5;
}

.table-hover .table-info:hover > td,
.table-hover .table-info:hover > th {
  background-color: #abdde5;
}

.table-warning,
.table-warning > th,
.table-warning > td {
  background-color: #ffeeba;
}

.table-warning th,
.table-warning td,
.table-warning thead th,
.table-warning tbody + tbody {
  border-color: #ffdf7e;
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;
}

.table-hover .table-warning:hover > td,
.table-hover .table-warning:hover > th {
  background-color: #ffe8a1;
}

.table-danger,
.table-danger > th,
.table-danger > td {
  background-color: #f5c6cb;
}

.table-danger th,
.table-danger td,
.table-danger thead th,
.table-danger tbody + tbody {
  border-color: #ed969e;
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;
}

.table-hover .table-danger:hover > td,
.table-hover .table-danger:hover > th {
  background-color: #f1b0b7;
}

.table-light,
.table-light > th,
.table-light > td {
  background-color: #f9f9f9;
}

.table-light th,
.table-light td,
.table-light thead th,
.table-light tbody + tbody {
  border-color: #f3f3f3;
}

.table-hover .table-light:hover {
  background-color: #ececec;
}

.table-hover .table-light:hover > td,
.table-hover .table-light:hover > th {
  background-color: #ececec;
}

.table-dark,
.table-dark > th,
.table-dark > td {
  background-color: #bdbdc0;
}

.table-dark th,
.table-dark td,
.table-dark thead th,
.table-dark tbody + tbody {
  border-color: #84848a;
}

.table-hover .table-dark:hover {
  background-color: #b0b0b4;
}

.table-hover .table-dark:hover > td,
.table-hover .table-dark:hover > th {
  background-color: #b0b0b4;
}

.table-active,
.table-active > th,
.table-active > td {
  background-color: rgba(19, 19, 30, 0.075);
}

.table-hover .table-active:hover {
  background-color: rgba(9, 9, 14, 0.075);
}

.table-hover .table-active:hover > td,
.table-hover .table-active:hover > th {
  background-color: rgba(9, 9, 14, 0.075);
}

.table .thead-dark th {
  color: #fff;
  background-color: #2B2F37;
  border-color: #222235;
}

.table .thead-light th {
  color: #13131e;
  background-color: #afbac0;
  border-color: #d1d9dd;
}

.table-dark {
  color: #fff;
  background-color: #2B2F37;
}

.table-dark th,
.table-dark td,
.table-dark thead th {
  border-color: #222235;
}

.table-dark.table-bordered {
  border: 0;
}

.table-dark.table-striped tbody tr:nth-of-type(odd) {
  background-color: #30353E;
}

.table-dark.table-hover tbody tr:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.075);
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-sm > .table-bordered {
    border: 0;
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-md > .table-bordered {
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-lg > .table-bordered {
    border: 0;
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  .table-responsive-xl > .table-bordered {
    border: 0;
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

.table-responsive > .table-bordered {
  border: 0;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.875rem + 4px);
  padding: 0.4375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #41444a;
  background-color: #fff;
  background-clip: padding-box;
  border: 2px solid #f5bd52;
  border-radius: 0;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}

.form-control::-ms-expand {
  background-color: transparent;
  border: 0;
}

.form-control:focus {
  color: #41444a;
  background-color: #fff;
  border-color: #f5bd52;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.form-control::placeholder {
  color: #757c84;
  opacity: 1;
}

.form-control:disabled, .form-control[readonly] {
  background-color: #d1d9dd;
  opacity: 1;
}

select.form-control:focus::-ms-value {
  color: #41444a;
  background-color: #fff;
}

.form-control-file,
.form-control-range {
  display: block;
  width: 100%;
}

.col-form-label {
  padding-top: calc(0.4375rem + 2px);
  padding-bottom: calc(0.4375rem + 2px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.75rem + 2px);
  padding-bottom: calc(0.75rem + 2px);
  font-size: 1rem;
  line-height: 1.5;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 2px);
  padding-bottom: calc(0.25rem + 2px);
  font-size: 0.875rem;
  line-height: 1.571428571;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding-top: 0.4375rem;
  padding-bottom: 0.4375rem;
  margin-bottom: 0;
  line-height: 1.5;
  color: #13131e;
  background-color: transparent;
  border: solid transparent;
  border-width: 2px 0;
}

.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  height: calc(1.571428571em + 0.5rem + 4px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.571428571;
  border-radius: 0;
}

.form-control-lg {
  height: calc(1.5em + 1.125rem + 4px);
  padding: 0.75rem 1rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

select.form-control[size], select.form-control[multiple] {
  height: auto;
}

textarea.form-control {
  height: auto;
}

.form-group {
  margin-bottom: 1rem;
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-row {
  display: flex;
  flex-wrap: wrap;
  margin-right: -5px;
  margin-left: -5px;
}

.form-row > .col,
.form-row > [class*="col-"] {
  padding-right: 5px;
  padding-left: 5px;
}

.form-check {
  position: relative;
  display: block;
  padding-left: 1.25rem;
}

.form-check-input {
  position: absolute;
  margin-top: 0.3rem;
  margin-left: -1.25rem;
}

.form-check-input:disabled ~ .form-check-label {
  color: #919ba2;
}

.form-check-label {
  margin-bottom: 0;
}

.form-check-inline {
  display: inline-flex;
  align-items: center;
  padding-left: 0;
  margin-right: 0.75rem;
}

.form-check-inline .form-check-input {
  position: static;
  margin-top: 0;
  margin-right: 0.3125rem;
  margin-left: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #28a745;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #28a745;
  border-radius: 0;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #28a745;
  padding-right: 2.375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.21875rem);
  background-size: calc(0.75em + 0.4375rem) calc(0.75em + 0.4375rem);
}

.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .form-control:valid ~ .valid-feedback,
.was-validated .form-control:valid ~ .valid-tooltip, .form-control.is-valid ~ .valid-feedback,
.form-control.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: 2.375rem;
  background-position: top calc(0.375em + 0.21875rem) right calc(0.375em + 0.21875rem);
}

.was-validated .custom-select:valid, .custom-select.is-valid {
  border-color: #28a745;
  padding-right: calc((1em + 0.875rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23292b30' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%2328a745' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e") #fff no-repeat center right 2rem/calc(0.75em + 0.4375rem) calc(0.75em + 0.4375rem);
}

.was-validated .custom-select:valid:focus, .custom-select.is-valid:focus {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-select:valid ~ .valid-feedback,
.was-validated .custom-select:valid ~ .valid-tooltip, .custom-select.is-valid ~ .valid-feedback,
.custom-select.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control-file:valid ~ .valid-feedback,
.was-validated .form-control-file:valid ~ .valid-tooltip, .form-control-file.is-valid ~ .valid-feedback,
.form-control-file.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #28a745;
}

.was-validated .form-check-input:valid ~ .valid-feedback,
.was-validated .form-check-input:valid ~ .valid-tooltip, .form-check-input.is-valid ~ .valid-feedback,
.form-check-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid ~ .custom-control-label, .custom-control-input.is-valid ~ .custom-control-label {
  color: #28a745;
}

.was-validated .custom-control-input:valid ~ .custom-control-label::before, .custom-control-input.is-valid ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-control-input:valid ~ .valid-feedback,
.was-validated .custom-control-input:valid ~ .valid-tooltip, .custom-control-input.is-valid ~ .valid-feedback,
.custom-control-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-control-input:valid:checked ~ .custom-control-label::before, .custom-control-input.is-valid:checked ~ .custom-control-label::before {
  border-color: #34ce57;
  background-color: #34ce57;
}

.was-validated .custom-control-input:valid:focus ~ .custom-control-label::before, .custom-control-input.is-valid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .custom-file-label, .custom-file-input.is-valid ~ .custom-file-label {
  border-color: #28a745;
}

.was-validated .custom-file-input:valid ~ .valid-feedback,
.was-validated .custom-file-input:valid ~ .valid-tooltip, .custom-file-input.is-valid ~ .valid-feedback,
.custom-file-input.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .custom-file-input:valid:focus ~ .custom-file-label, .custom-file-input.is-valid:focus ~ .custom-file-label {
  border-color: #28a745;
  box-shadow: 0 0 0 0.2rem rgba(40, 167, 69, 0.25);
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 80%;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.5rem 1rem;
  margin-top: .1rem;
  font-size: 0.875rem;
  line-height: 1.5;
  color: #fff;
  background-color: #dc3545;
  border-radius: 0;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: 2.375rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E");
  background-repeat: no-repeat;
  background-position: center right calc(0.375em + 0.21875rem);
  background-size: calc(0.75em + 0.4375rem) calc(0.75em + 0.4375rem);
}

.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-control:invalid ~ .invalid-feedback,
.was-validated .form-control:invalid ~ .invalid-tooltip, .form-control.is-invalid ~ .invalid-feedback,
.form-control.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: 2.375rem;
  background-position: top calc(0.375em + 0.21875rem) right calc(0.375em + 0.21875rem);
}

.was-validated .custom-select:invalid, .custom-select.is-invalid {
  border-color: #dc3545;
  padding-right: calc((1em + 0.875rem) * 3 / 4 + 2rem);
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23292b30' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px, url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='%23dc3545' viewBox='-2 -2 7 7'%3e%3cpath stroke='%23dc3545' d='M0 0l3 3m0-3L0 3'/%3e%3ccircle r='.5'/%3e%3ccircle cx='3' r='.5'/%3e%3ccircle cy='3' r='.5'/%3e%3ccircle cx='3' cy='3' r='.5'/%3e%3c/svg%3E") #fff no-repeat center right 2rem/calc(0.75em + 0.4375rem) calc(0.75em + 0.4375rem);
}

.was-validated .custom-select:invalid:focus, .custom-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-select:invalid ~ .invalid-feedback,
.was-validated .custom-select:invalid ~ .invalid-tooltip, .custom-select.is-invalid ~ .invalid-feedback,
.custom-select.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control-file:invalid ~ .invalid-feedback,
.was-validated .form-control-file:invalid ~ .invalid-tooltip, .form-control-file.is-invalid ~ .invalid-feedback,
.form-control-file.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.was-validated .form-check-input:invalid ~ .invalid-feedback,
.was-validated .form-check-input:invalid ~ .invalid-tooltip, .form-check-input.is-invalid ~ .invalid-feedback,
.form-check-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label, .custom-control-input.is-invalid ~ .custom-control-label {
  color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .custom-control-label::before, .custom-control-input.is-invalid ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-control-input:invalid ~ .invalid-feedback,
.was-validated .custom-control-input:invalid ~ .invalid-tooltip, .custom-control-input.is-invalid ~ .invalid-feedback,
.custom-control-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-control-input:invalid:checked ~ .custom-control-label::before, .custom-control-input.is-invalid:checked ~ .custom-control-label::before {
  border-color: #e4606d;
  background-color: #e4606d;
}

.was-validated .custom-control-input:invalid:focus ~ .custom-control-label::before, .custom-control-input.is-invalid:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label::before, .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .custom-file-label, .custom-file-input.is-invalid ~ .custom-file-label {
  border-color: #dc3545;
}

.was-validated .custom-file-input:invalid ~ .invalid-feedback,
.was-validated .custom-file-input:invalid ~ .invalid-tooltip, .custom-file-input.is-invalid ~ .invalid-feedback,
.custom-file-input.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .custom-file-input:invalid:focus ~ .custom-file-label, .custom-file-input.is-invalid:focus ~ .custom-file-label {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
}

.form-inline {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
}

.form-inline .form-check {
  width: 100%;
}

@media (min-width: 576px) {
  .form-inline label {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0;
  }
  .form-inline .form-group {
    display: flex;
    flex: 0 0 auto;
    flex-flow: row wrap;
    align-items: center;
    margin-bottom: 0;
  }
  .form-inline .form-control {
    display: inline-block;
    width: auto;
    vertical-align: middle;
  }
  .form-inline .form-control-plaintext {
    display: inline-block;
  }
  .form-inline .input-group,
  .form-inline .custom-select {
    width: auto;
  }
  .form-inline .form-check {
    display: flex;
    align-items: center;
    justify-content: center;
    width: auto;
    padding-left: 0;
  }
  .form-inline .form-check-input {
    position: relative;
    flex-shrink: 0;
    margin-top: 0;
    margin-right: 0.25rem;
    margin-left: 0;
  }
  .form-inline .custom-control {
    align-items: center;
    justify-content: center;
  }
  .form-inline .custom-control-label {
    margin-bottom: 0;
  }
}

.fade {
  transition: opacity 0.15s linear;
}

@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}

.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropright,
.dropdown,
.dropleft {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}

.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}

.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: #13131e;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid transparent;
  border-radius: 0;
}

.dropdown-menu-left {
  right: auto;
  left: 0;
}

.dropdown-menu-right {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-sm-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 768px) {
  .dropdown-menu-md-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-md-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 992px) {
  .dropdown-menu-lg-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-lg-right {
    right: 0;
    left: auto;
  }
}

@media (min-width: 1200px) {
  .dropdown-menu-xl-left {
    right: auto;
    left: 0;
  }
  .dropdown-menu-xl-right {
    right: 0;
    left: auto;
  }
}

.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}

.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}

.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-menu {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}

.dropright .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}

.dropright .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropright .dropdown-toggle::after {
  vertical-align: 0;
}

.dropleft .dropdown-menu {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}

.dropleft .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}

.dropleft .dropdown-toggle::after {
  display: none;
}

.dropleft .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}

.dropleft .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropleft .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-menu[x-placement^="top"], .dropdown-menu[x-placement^="right"], .dropdown-menu[x-placement^="bottom"], .dropdown-menu[x-placement^="left"] {
  right: auto;
  bottom: auto;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid #d1d9dd;
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #13131e;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}

.dropdown-item:hover, .dropdown-item:focus {
  color: #09090e;
  text-decoration: none;
  background-color: #e8e8e8;
}

.dropdown-item.active, .dropdown-item:active {
  color: #13131e;
  text-decoration: none;
  background-color: #d1d9dd;
}

.dropdown-item.disabled, .dropdown-item:disabled {
  color: #5a6067;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1.5rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #5a6067;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1.5rem;
  color: #13131e;
}

.custom-control {
  position: relative;
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5rem;
}

.custom-control-inline {
  display: inline-flex;
  margin-right: 1rem;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control-input:checked ~ .custom-control-label::before {
  color: #fff;
  border-color: #f5bd52;
  background-color: #f5bd52;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.custom-control-input:focus:not(:checked) ~ .custom-control-label::before {
  border-color: #f5bd52;
}

.custom-control-input:not(:disabled):active ~ .custom-control-label::before {
  color: #fff;
  background-color: #fffdfb;
  border-color: #fffdfb;
}

.custom-control-input:disabled ~ .custom-control-label {
  color: #5a6067;
}

.custom-control-input:disabled ~ .custom-control-label::before {
  background-color: #d1d9dd;
}

.custom-control-label {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

.custom-control-label::before {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  pointer-events: none;
  content: "";
  background-color: #fff;
  border: #f5bd52 solid 2px;
}

.custom-control-label::after {
  position: absolute;
  top: 0.25rem;
  left: -1.5rem;
  display: block;
  width: 1rem;
  height: 1rem;
  content: "";
  background: no-repeat 50% / 50% 50%;
}

.custom-checkbox .custom-control-label::before {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::before {
  border-color: #f5bd52;
  background-color: #f5bd52;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='%23fff' d='M0 2h4'/%3e%3c/svg%3e");
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(245, 189, 82, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label::before {
  background-color: rgba(245, 189, 82, 0.5);
}

.custom-radio .custom-control-label::before {
  border-radius: 50%;
}

.custom-radio .custom-control-input:checked ~ .custom-control-label::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(245, 189, 82, 0.5);
}

.custom-switch {
  padding-left: 2.25rem;
}

.custom-switch .custom-control-label::before {
  left: -2.25rem;
  width: 1.75rem;
  pointer-events: all;
  border-radius: 0.5rem;
}

.custom-switch .custom-control-label::after {
  top: calc(0.25rem + 4px);
  left: calc(-2.25rem + 4px);
  width: calc(1rem - 8px);
  height: calc(1rem - 8px);
  background-color: #f5bd52;
  border-radius: 0.5rem;
  transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-switch .custom-control-label::after {
    transition: none;
  }
}

.custom-switch .custom-control-input:checked ~ .custom-control-label::after {
  background-color: #fff;
  transform: translateX(0.75rem);
}

.custom-switch .custom-control-input:disabled:checked ~ .custom-control-label::before {
  background-color: rgba(245, 189, 82, 0.5);
}

.custom-select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.875rem + 4px);
  padding: 0.4375rem 2rem 0.4375rem 1rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #41444a;
  vertical-align: middle;
  background: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 5'%3e%3cpath fill='%23292b30' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e") no-repeat right 1rem center/8px 10px;
  background-color: #fff;
  border: 2px solid #f5bd52;
  border-radius: 0;
  appearance: none;
}

.custom-select:focus {
  border-color: #f5bd52;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.custom-select:focus::-ms-value {
  color: #41444a;
  background-color: #fff;
}

.custom-select[multiple], .custom-select[size]:not([size="1"]) {
  height: auto;
  padding-right: 1rem;
  background-image: none;
}

.custom-select:disabled {
  color: #5a6067;
  background-color: #d1d9dd;
}

.custom-select::-ms-expand {
  display: none;
}

.custom-select-sm {
  height: calc(1.571428571em + 0.5rem + 4px);
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.custom-select-lg {
  height: calc(1.5em + 1.125rem + 4px);
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  padding-left: 1rem;
  font-size: 1rem;
}

.custom-file {
  position: relative;
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.875rem + 4px);
  margin-bottom: 0;
}

.custom-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.875rem + 4px);
  margin: 0;
  opacity: 0;
}

.custom-file-input:focus ~ .custom-file-label {
  border-color: #f5bd52;
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.custom-file-input:disabled ~ .custom-file-label {
  background-color: #d1d9dd;
}

.custom-file-input:lang(en) ~ .custom-file-label::after {
  content: "Browse";
}

.custom-file-input ~ .custom-file-label[data-browse]::after {
  content: attr(data-browse);
}

.custom-file-label {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  height: calc(1.5em + 0.875rem + 4px);
  padding: 0.4375rem 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #41444a;
  background-color: #fff;
  border: 2px solid #f5bd52;
  border-radius: 0;
}

.custom-file-label::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 3;
  display: block;
  height: 2.375rem;
  padding: 0.4375rem 1rem;
  line-height: 1.5;
  color: #41444a;
  content: "Browse";
  background-color: #fff;
  border-left: inherit;
  border-radius: 0 0 0 0;
}

.custom-range {
  width: 100%;
  height: calc(0.625rem + 0.4rem);
  padding: 0;
  background-color: transparent;
  appearance: none;
}

.custom-range:focus {
  outline: none;
}

.custom-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.custom-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.custom-range:focus::-ms-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.custom-range::-moz-focus-outer {
  border: 0;
}

.custom-range::-webkit-slider-thumb {
  width: 0.625rem;
  height: 0.625rem;
  margin-top: -0.25rem;
  background-color: #f5bd52;
  border: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-webkit-slider-thumb {
    transition: none;
  }
}

.custom-range::-webkit-slider-thumb:active {
  background-color: #f8d085;
}

.custom-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: #f5bd52;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-moz-range-thumb {
  width: 0.625rem;
  height: 0.625rem;
  background-color: #f5bd52;
  border: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-moz-range-thumb {
    transition: none;
  }
}

.custom-range::-moz-range-thumb:active {
  background-color: #f8d085;
}

.custom-range::-moz-range-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: #f5bd52;
  border-color: transparent;
  border-radius: 1rem;
}

.custom-range::-ms-thumb {
  width: 0.625rem;
  height: 0.625rem;
  margin-top: 0;
  margin-right: 0.2rem;
  margin-left: 0.2rem;
  background-color: #f5bd52;
  border: 0;
  border-radius: 0;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}

@media (prefers-reduced-motion: reduce) {
  .custom-range::-ms-thumb {
    transition: none;
  }
}

.custom-range::-ms-thumb:active {
  background-color: #f8d085;
}

.custom-range::-ms-track {
  width: 100%;
  height: 0.125rem;
  color: transparent;
  cursor: pointer;
  background-color: transparent;
  border-color: transparent;
  border-width: 0.3125rem;
}

.custom-range::-ms-fill-lower {
  background-color: #f5bd52;
  border-radius: 1rem;
}

.custom-range::-ms-fill-upper {
  margin-right: 15px;
  background-color: #f5bd52;
  border-radius: 1rem;
}

.custom-range:disabled::-webkit-slider-thumb {
  background-color: #757c84;
}

.custom-range:disabled::-webkit-slider-runnable-track {
  cursor: default;
}

.custom-range:disabled::-moz-range-thumb {
  background-color: #757c84;
}

.custom-range:disabled::-moz-range-track {
  cursor: default;
}

.custom-range:disabled::-ms-thumb {
  background-color: #757c84;
}

.custom-control-label::before,
.custom-file-label,
.custom-select {
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
  .custom-control-label::before,
  .custom-file-label,
  .custom-select {
    transition: none;
  }
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 0;
  list-style: none;
  background-color: transparent;
  border-radius: 0;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 1rem;
}

.breadcrumb-item + .breadcrumb-item::before {
  display: inline-block;
  padding-right: 1rem;
  color: inherit;
  content: url("data:image/svg+xml;charset=UTF-8,%3csvg width='6' height='10' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M5.665 1.991L2.188 5.434l3.477 3.442-1.07 1.058-4.555-4.5 4.554-4.5z' fill='%23000' fill-rule='evenodd'/%3e%3c/svg%3e");
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: underline;
}

.breadcrumb-item + .breadcrumb-item:hover::before {
  text-decoration: none;
}

.breadcrumb-item.active {
  color: inherit;
}

.media {
  display: flex;
  align-items: flex-start;
}

.media-body {
  flex: 1;
}

.modal-open {
  overflow: hidden;
}

.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}

.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}

@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}

.modal.show .modal-dialog {
  transform: none;
}

.modal-dialog-scrollable {
  display: flex;
  max-height: calc(100% - 1rem);
}

.modal-dialog-scrollable .modal-content {
  max-height: calc(100vh - 1rem);
  overflow: hidden;
}

.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
  flex-shrink: 0;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-dialog-centered::before {
  display: block;
  height: calc(100vh - 1rem);
  content: "";
}

.modal-dialog-centered.modal-dialog-scrollable {
  flex-direction: column;
  justify-content: center;
  height: 100%;
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
  max-height: none;
}

.modal-dialog-centered.modal-dialog-scrollable::before {
  content: none;
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(19, 19, 30, 0.2);
  border-radius: 0;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #040a18;
}

.modal-backdrop.fade {
  opacity: 0;
}

.modal-backdrop.show {
  opacity: 0.8;
}

.modal-header {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 3rem 3rem;
  border-bottom: 1px solid #d1d9dd;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.modal-header .close {
  padding: 3rem 3rem;
  margin: -3rem -3rem -3rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 3rem;
}

.modal-footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 3rem;
  border-top: 1px solid #d1d9dd;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}

.modal-footer > :not(:first-child) {
  margin-left: .25rem;
}

.modal-footer > :not(:last-child) {
  margin-right: .25rem;
}

.modal-scrollbar-measure {
  position: absolute;
  top: -9999px;
  width: 50px;
  height: 50px;
  overflow: scroll;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 585px;
    margin: 1.75rem auto;
  }
  .modal-dialog-scrollable {
    max-height: calc(100% - 3.5rem);
  }
  .modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 3.5rem);
  }
  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }
  .modal-dialog-centered::before {
    height: calc(100vh - 3.5rem);
  }
  .modal-sm {
    max-width: 300px;
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}

@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}

.tooltip {
  position: absolute;
  z-index: 1070;
  display: block;
  margin: 0;
  font-family: "Benton Sans", "Libre Franklin", sans-serif;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}

.tooltip.show {
  opacity: 1;
}

.tooltip .arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}

.tooltip .arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[x-placement^="top"] {
  padding: 0.4rem 0;
}

.bs-tooltip-top .arrow, .bs-tooltip-auto[x-placement^="top"] .arrow {
  bottom: 0;
}

.bs-tooltip-top .arrow::before, .bs-tooltip-auto[x-placement^="top"] .arrow::before {
  top: 0;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #fff;
}

.bs-tooltip-right, .bs-tooltip-auto[x-placement^="right"] {
  padding: 0 0.4rem;
}

.bs-tooltip-right .arrow, .bs-tooltip-auto[x-placement^="right"] .arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-right .arrow::before, .bs-tooltip-auto[x-placement^="right"] .arrow::before {
  right: 0;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #fff;
}

.bs-tooltip-bottom, .bs-tooltip-auto[x-placement^="bottom"] {
  padding: 0.4rem 0;
}

.bs-tooltip-bottom .arrow, .bs-tooltip-auto[x-placement^="bottom"] .arrow {
  top: 0;
}

.bs-tooltip-bottom .arrow::before, .bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  bottom: 0;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #fff;
}

.bs-tooltip-left, .bs-tooltip-auto[x-placement^="left"] {
  padding: 0 0.4rem;
}

.bs-tooltip-left .arrow, .bs-tooltip-auto[x-placement^="left"] .arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}

.bs-tooltip-left .arrow::before, .bs-tooltip-auto[x-placement^="left"] .arrow::before {
  left: 0;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #fff;
}

.tooltip-inner {
  max-width: 20rem;
  padding: 0.5rem 1rem;
  color: #13131e;
  text-align: center;
  background-color: #fff;
  border-radius: 0;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.d-none {
  display: none !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

@media (min-width: 576px) {
  .d-sm-none {
    display: none !important;
  }
  .d-sm-inline {
    display: inline !important;
  }
  .d-sm-inline-block {
    display: inline-block !important;
  }
  .d-sm-block {
    display: block !important;
  }
  .d-sm-table {
    display: table !important;
  }
  .d-sm-table-row {
    display: table-row !important;
  }
  .d-sm-table-cell {
    display: table-cell !important;
  }
  .d-sm-flex {
    display: flex !important;
  }
  .d-sm-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 768px) {
  .d-md-none {
    display: none !important;
  }
  .d-md-inline {
    display: inline !important;
  }
  .d-md-inline-block {
    display: inline-block !important;
  }
  .d-md-block {
    display: block !important;
  }
  .d-md-table {
    display: table !important;
  }
  .d-md-table-row {
    display: table-row !important;
  }
  .d-md-table-cell {
    display: table-cell !important;
  }
  .d-md-flex {
    display: flex !important;
  }
  .d-md-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 992px) {
  .d-lg-none {
    display: none !important;
  }
  .d-lg-inline {
    display: inline !important;
  }
  .d-lg-inline-block {
    display: inline-block !important;
  }
  .d-lg-block {
    display: block !important;
  }
  .d-lg-table {
    display: table !important;
  }
  .d-lg-table-row {
    display: table-row !important;
  }
  .d-lg-table-cell {
    display: table-cell !important;
  }
  .d-lg-flex {
    display: flex !important;
  }
  .d-lg-inline-flex {
    display: inline-flex !important;
  }
}

@media (min-width: 1200px) {
  .d-xl-none {
    display: none !important;
  }
  .d-xl-inline {
    display: inline !important;
  }
  .d-xl-inline-block {
    display: inline-block !important;
  }
  .d-xl-block {
    display: block !important;
  }
  .d-xl-table {
    display: table !important;
  }
  .d-xl-table-row {
    display: table-row !important;
  }
  .d-xl-table-cell {
    display: table-cell !important;
  }
  .d-xl-flex {
    display: flex !important;
  }
  .d-xl-inline-flex {
    display: inline-flex !important;
  }
}

@media print {
  .d-print-none {
    display: none !important;
  }
  .d-print-inline {
    display: inline !important;
  }
  .d-print-inline-block {
    display: inline-block !important;
  }
  .d-print-block {
    display: block !important;
  }
  .d-print-table {
    display: table !important;
  }
  .d-print-table-row {
    display: table-row !important;
  }
  .d-print-table-cell {
    display: table-cell !important;
  }
  .d-print-flex {
    display: flex !important;
  }
  .d-print-inline-flex {
    display: inline-flex !important;
  }
}

.float-left {
  float: left !important;
}

.float-right {
  float: right !important;
}

.float-none {
  float: none !important;
}

@media (min-width: 576px) {
  .float-sm-left {
    float: left !important;
  }
  .float-sm-right {
    float: right !important;
  }
  .float-sm-none {
    float: none !important;
  }
}

@media (min-width: 768px) {
  .float-md-left {
    float: left !important;
  }
  .float-md-right {
    float: right !important;
  }
  .float-md-none {
    float: none !important;
  }
}

@media (min-width: 992px) {
  .float-lg-left {
    float: left !important;
  }
  .float-lg-right {
    float: right !important;
  }
  .float-lg-none {
    float: none !important;
  }
}

@media (min-width: 1200px) {
  .float-xl-left {
    float: left !important;
  }
  .float-xl-right {
    float: right !important;
  }
  .float-xl-none {
    float: none !important;
  }
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

@supports (position: sticky) {
  .sticky-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}

.sr-only-focusable:active, .sr-only-focusable:focus {
  position: static;
  width: auto;
  height: auto;
  overflow: visible;
  clip: auto;
  white-space: normal;
}

.text-monospace {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !important;
}

.text-justify {
  text-align: justify !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.text-left {
  text-align: left !important;
}

.text-right {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

@media (min-width: 576px) {
  .text-sm-left {
    text-align: left !important;
  }
  .text-sm-right {
    text-align: right !important;
  }
  .text-sm-center {
    text-align: center !important;
  }
}

@media (min-width: 768px) {
  .text-md-left {
    text-align: left !important;
  }
  .text-md-right {
    text-align: right !important;
  }
  .text-md-center {
    text-align: center !important;
  }
}

@media (min-width: 992px) {
  .text-lg-left {
    text-align: left !important;
  }
  .text-lg-right {
    text-align: right !important;
  }
  .text-lg-center {
    text-align: center !important;
  }
}

@media (min-width: 1200px) {
  .text-xl-left {
    text-align: left !important;
  }
  .text-xl-right {
    text-align: right !important;
  }
  .text-xl-center {
    text-align: center !important;
  }
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.font-weight-light {
  font-weight: 300 !important;
}

.font-weight-lighter {
  font-weight: lighter !important;
}

.font-weight-normal {
  font-weight: 400 !important;
}

.font-weight-bold {
  font-weight: 700 !important;
}

.font-weight-bolder {
  font-weight: bolder !important;
}

.font-italic {
  font-style: italic !important;
}

.text-white {
  color: #fff !important;
}

.text-primary {
  color: #f5bd52 !important;
}

a.text-primary:hover, a.text-primary:focus {
  color: #eda00e !important;
}

.text-secondary {
  color: #082948 !important;
}

a.text-secondary:hover, a.text-secondary:focus {
  color: #000203 !important;
}

.text-success {
  color: #28a745 !important;
}

a.text-success:hover, a.text-success:focus {
  color: #19692c !important;
}

.text-info {
  color: #17a2b8 !important;
}

a.text-info:hover, a.text-info:focus {
  color: #0f6674 !important;
}

.text-warning {
  color: #ffc107 !important;
}

a.text-warning:hover, a.text-warning:focus {
  color: #ba8b00 !important;
}

.text-danger {
  color: #dc3545 !important;
}

a.text-danger:hover, a.text-danger:focus {
  color: #a71d2a !important;
}

.text-light {
  color: #e8e8e8 !important;
}

a.text-light:hover, a.text-light:focus {
  color: #c2c2c2 !important;
}

.text-dark {
  color: #13131e !important;
}

a.text-dark:hover, a.text-dark:focus {
  color: black !important;
}

.text-body {
  color: #13131e !important;
}

.text-muted {
  color: #919ba2 !important;
}

.text-black-50 {
  color: rgba(19, 19, 30, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-hide {
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-break {
  word-break: break-word !important;
  overflow-wrap: break-word !important;
}

.text-reset {
  color: inherit !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  pointer-events: auto;
  content: "";
  background-color: rgba(0, 0, 0, 0);
}

@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  a:not(.btn) {
    text-decoration: underline;
  }
  abbr[title]::after {
    content: " (" attr(title) ")";
  }
  pre {
    white-space: pre-wrap !important;
  }
  pre,
  blockquote {
    border: 1px solid #757c84;
    page-break-inside: avoid;
  }
  thead {
    display: table-header-group;
  }
  tr,
  img {
    page-break-inside: avoid;
  }
  p,
  h2,
  h3 {
    orphans: 3;
    widows: 3;
  }
  h2,
  h3 {
    page-break-after: avoid;
  }
  @page {
    size: a3;
  }
  body {
    min-width: 992px !important;
  }
  .container {
    min-width: 992px !important;
  }
  .navbar {
    display: none;
  }
  .badge {
    border: 1px solid #13131e;
  }
  .table {
    border-collapse: collapse !important;
  }
  .table td,
  .table th {
    background-color: #fff !important;
  }
  .table-bordered th,
  .table-bordered td {
    border: 1px solid #afbac0 !important;
  }
  .table-dark {
    color: inherit;
  }
  .table-dark th,
  .table-dark td,
  .table-dark thead th,
  .table-dark tbody + tbody {
    border-color: #d1d9dd;
  }
  .table .thead-dark th {
    color: inherit;
    border-color: #d1d9dd;
  }
}

/* Slider */
.slick-loading .slick-list {
  background: #fff url("./ajax-loader.gif") center center no-repeat;
}

/* Icons */
@font-face {
  font-family: "slick";
  src: url("./fonts/slick.eot");
  src: url("./fonts/slick.eot?#iefix") format("embedded-opentype"), url("./fonts/slick.woff") format("woff"), url("./fonts/slick.ttf") format("truetype"), url("./fonts/slick.svg#slick") format("svg");
  font-weight: normal;
  font-style: normal;
}

/* Arrows */
.slick-prev,
.slick-next {
  position: absolute;
  display: block;
  height: 20px;
  width: 20px;
  line-height: 0px;
  font-size: 0px;
  cursor: pointer;
  background: transparent;
  color: transparent;
  top: 50%;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  padding: 0;
  border: none;
  outline: none;
}

.slick-prev:hover, .slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  outline: none;
  background: transparent;
  color: transparent;
}

.slick-prev:hover:before, .slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}

.slick-prev:before,
.slick-next:before {
  font-family: "slick";
  font-size: 20px;
  line-height: 1;
  color: white;
  opacity: 0.75;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-prev {
  left: -25px;
}

[dir="rtl"] .slick-prev {
  left: auto;
  right: -25px;
}

.slick-prev:before {
  content: "←";
}

[dir="rtl"] .slick-prev:before {
  content: "→";
}

.slick-next {
  right: -25px;
}

[dir="rtl"] .slick-next {
  left: -25px;
  right: auto;
}

.slick-next:before {
  content: "→";
}

[dir="rtl"] .slick-next:before {
  content: "←";
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  position: absolute;
  bottom: -25px;
  list-style: none;
  display: block;
  text-align: center;
  padding: 0;
  margin: 0;
  width: 100%;
}

.slick-dots li {
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
  margin: 0 5px;
  padding: 0;
  cursor: pointer;
}

.slick-dots li button {
  border: 0;
  background: transparent;
  display: block;
  height: 20px;
  width: 20px;
  outline: none;
  line-height: 0px;
  font-size: 0px;
  color: transparent;
  padding: 5px;
  cursor: pointer;
}

.slick-dots li button:hover, .slick-dots li button:focus {
  outline: none;
}

.slick-dots li button:hover:before, .slick-dots li button:focus:before {
  opacity: 1;
}

.slick-dots li button:before {
  position: absolute;
  top: 0;
  left: 0;
  content: "•";
  width: 20px;
  height: 20px;
  font-family: "slick";
  font-size: 6px;
  line-height: 20px;
  text-align: center;
  color: black;
  opacity: 0.25;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.slick-dots li.slick-active button:before {
  color: black;
  opacity: 0.75;
}

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}

.slick-track:after {
  clear: both;
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}

[dir="rtl"] .slick-slide {
  float: right;
}

.slick-slide img {
  display: block;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

.slick-arrow.slick-hidden {
  display: none;
}

body[data-aos-duration='50'] [data-aos], [data-aos][data-aos][data-aos-duration='50'] {
  transition-duration: 50ms;
}

body[data-aos-delay='50'] [data-aos], [data-aos][data-aos][data-aos-delay='50'] {
  transition-delay: 0;
}

body[data-aos-delay='50'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='50'].aos-animate {
  transition-delay: 50ms;
}

body[data-aos-duration='100'] [data-aos], [data-aos][data-aos][data-aos-duration='100'] {
  transition-duration: 100ms;
}

body[data-aos-delay='100'] [data-aos], [data-aos][data-aos][data-aos-delay='100'] {
  transition-delay: 0;
}

body[data-aos-delay='100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='100'].aos-animate {
  transition-delay: 100ms;
}

body[data-aos-duration='150'] [data-aos], [data-aos][data-aos][data-aos-duration='150'] {
  transition-duration: 150ms;
}

body[data-aos-delay='150'] [data-aos], [data-aos][data-aos][data-aos-delay='150'] {
  transition-delay: 0;
}

body[data-aos-delay='150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='150'].aos-animate {
  transition-delay: 150ms;
}

body[data-aos-duration='200'] [data-aos], [data-aos][data-aos][data-aos-duration='200'] {
  transition-duration: 200ms;
}

body[data-aos-delay='200'] [data-aos], [data-aos][data-aos][data-aos-delay='200'] {
  transition-delay: 0;
}

body[data-aos-delay='200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='200'].aos-animate {
  transition-delay: 200ms;
}

body[data-aos-duration='250'] [data-aos], [data-aos][data-aos][data-aos-duration='250'] {
  transition-duration: 250ms;
}

body[data-aos-delay='250'] [data-aos], [data-aos][data-aos][data-aos-delay='250'] {
  transition-delay: 0;
}

body[data-aos-delay='250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='250'].aos-animate {
  transition-delay: 250ms;
}

body[data-aos-duration='300'] [data-aos], [data-aos][data-aos][data-aos-duration='300'] {
  transition-duration: 300ms;
}

body[data-aos-delay='300'] [data-aos], [data-aos][data-aos][data-aos-delay='300'] {
  transition-delay: 0;
}

body[data-aos-delay='300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='300'].aos-animate {
  transition-delay: 300ms;
}

body[data-aos-duration='350'] [data-aos], [data-aos][data-aos][data-aos-duration='350'] {
  transition-duration: 350ms;
}

body[data-aos-delay='350'] [data-aos], [data-aos][data-aos][data-aos-delay='350'] {
  transition-delay: 0;
}

body[data-aos-delay='350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='350'].aos-animate {
  transition-delay: 350ms;
}

body[data-aos-duration='400'] [data-aos], [data-aos][data-aos][data-aos-duration='400'] {
  transition-duration: 400ms;
}

body[data-aos-delay='400'] [data-aos], [data-aos][data-aos][data-aos-delay='400'] {
  transition-delay: 0;
}

body[data-aos-delay='400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='400'].aos-animate {
  transition-delay: 400ms;
}

body[data-aos-duration='450'] [data-aos], [data-aos][data-aos][data-aos-duration='450'] {
  transition-duration: 450ms;
}

body[data-aos-delay='450'] [data-aos], [data-aos][data-aos][data-aos-delay='450'] {
  transition-delay: 0;
}

body[data-aos-delay='450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='450'].aos-animate {
  transition-delay: 450ms;
}

body[data-aos-duration='500'] [data-aos], [data-aos][data-aos][data-aos-duration='500'] {
  transition-duration: 500ms;
}

body[data-aos-delay='500'] [data-aos], [data-aos][data-aos][data-aos-delay='500'] {
  transition-delay: 0;
}

body[data-aos-delay='500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='500'].aos-animate {
  transition-delay: 500ms;
}

body[data-aos-duration='550'] [data-aos], [data-aos][data-aos][data-aos-duration='550'] {
  transition-duration: 550ms;
}

body[data-aos-delay='550'] [data-aos], [data-aos][data-aos][data-aos-delay='550'] {
  transition-delay: 0;
}

body[data-aos-delay='550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='550'].aos-animate {
  transition-delay: 550ms;
}

body[data-aos-duration='600'] [data-aos], [data-aos][data-aos][data-aos-duration='600'] {
  transition-duration: 600ms;
}

body[data-aos-delay='600'] [data-aos], [data-aos][data-aos][data-aos-delay='600'] {
  transition-delay: 0;
}

body[data-aos-delay='600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='600'].aos-animate {
  transition-delay: 600ms;
}

body[data-aos-duration='650'] [data-aos], [data-aos][data-aos][data-aos-duration='650'] {
  transition-duration: 650ms;
}

body[data-aos-delay='650'] [data-aos], [data-aos][data-aos][data-aos-delay='650'] {
  transition-delay: 0;
}

body[data-aos-delay='650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='650'].aos-animate {
  transition-delay: 650ms;
}

body[data-aos-duration='700'] [data-aos], [data-aos][data-aos][data-aos-duration='700'] {
  transition-duration: 700ms;
}

body[data-aos-delay='700'] [data-aos], [data-aos][data-aos][data-aos-delay='700'] {
  transition-delay: 0;
}

body[data-aos-delay='700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='700'].aos-animate {
  transition-delay: 700ms;
}

body[data-aos-duration='750'] [data-aos], [data-aos][data-aos][data-aos-duration='750'] {
  transition-duration: 750ms;
}

body[data-aos-delay='750'] [data-aos], [data-aos][data-aos][data-aos-delay='750'] {
  transition-delay: 0;
}

body[data-aos-delay='750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='750'].aos-animate {
  transition-delay: 750ms;
}

body[data-aos-duration='800'] [data-aos], [data-aos][data-aos][data-aos-duration='800'] {
  transition-duration: 800ms;
}

body[data-aos-delay='800'] [data-aos], [data-aos][data-aos][data-aos-delay='800'] {
  transition-delay: 0;
}

body[data-aos-delay='800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='800'].aos-animate {
  transition-delay: 800ms;
}

body[data-aos-duration='850'] [data-aos], [data-aos][data-aos][data-aos-duration='850'] {
  transition-duration: 850ms;
}

body[data-aos-delay='850'] [data-aos], [data-aos][data-aos][data-aos-delay='850'] {
  transition-delay: 0;
}

body[data-aos-delay='850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='850'].aos-animate {
  transition-delay: 850ms;
}

body[data-aos-duration='900'] [data-aos], [data-aos][data-aos][data-aos-duration='900'] {
  transition-duration: 900ms;
}

body[data-aos-delay='900'] [data-aos], [data-aos][data-aos][data-aos-delay='900'] {
  transition-delay: 0;
}

body[data-aos-delay='900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='900'].aos-animate {
  transition-delay: 900ms;
}

body[data-aos-duration='950'] [data-aos], [data-aos][data-aos][data-aos-duration='950'] {
  transition-duration: 950ms;
}

body[data-aos-delay='950'] [data-aos], [data-aos][data-aos][data-aos-delay='950'] {
  transition-delay: 0;
}

body[data-aos-delay='950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='950'].aos-animate {
  transition-delay: 950ms;
}

body[data-aos-duration='1000'] [data-aos], [data-aos][data-aos][data-aos-duration='1000'] {
  transition-duration: 1000ms;
}

body[data-aos-delay='1000'] [data-aos], [data-aos][data-aos][data-aos-delay='1000'] {
  transition-delay: 0;
}

body[data-aos-delay='1000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1000'].aos-animate {
  transition-delay: 1000ms;
}

body[data-aos-duration='1050'] [data-aos], [data-aos][data-aos][data-aos-duration='1050'] {
  transition-duration: 1050ms;
}

body[data-aos-delay='1050'] [data-aos], [data-aos][data-aos][data-aos-delay='1050'] {
  transition-delay: 0;
}

body[data-aos-delay='1050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1050'].aos-animate {
  transition-delay: 1050ms;
}

body[data-aos-duration='1100'] [data-aos], [data-aos][data-aos][data-aos-duration='1100'] {
  transition-duration: 1100ms;
}

body[data-aos-delay='1100'] [data-aos], [data-aos][data-aos][data-aos-delay='1100'] {
  transition-delay: 0;
}

body[data-aos-delay='1100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1100'].aos-animate {
  transition-delay: 1100ms;
}

body[data-aos-duration='1150'] [data-aos], [data-aos][data-aos][data-aos-duration='1150'] {
  transition-duration: 1150ms;
}

body[data-aos-delay='1150'] [data-aos], [data-aos][data-aos][data-aos-delay='1150'] {
  transition-delay: 0;
}

body[data-aos-delay='1150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1150'].aos-animate {
  transition-delay: 1150ms;
}

body[data-aos-duration='1200'] [data-aos], [data-aos][data-aos][data-aos-duration='1200'] {
  transition-duration: 1200ms;
}

body[data-aos-delay='1200'] [data-aos], [data-aos][data-aos][data-aos-delay='1200'] {
  transition-delay: 0;
}

body[data-aos-delay='1200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1200'].aos-animate {
  transition-delay: 1200ms;
}

body[data-aos-duration='1250'] [data-aos], [data-aos][data-aos][data-aos-duration='1250'] {
  transition-duration: 1250ms;
}

body[data-aos-delay='1250'] [data-aos], [data-aos][data-aos][data-aos-delay='1250'] {
  transition-delay: 0;
}

body[data-aos-delay='1250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1250'].aos-animate {
  transition-delay: 1250ms;
}

body[data-aos-duration='1300'] [data-aos], [data-aos][data-aos][data-aos-duration='1300'] {
  transition-duration: 1300ms;
}

body[data-aos-delay='1300'] [data-aos], [data-aos][data-aos][data-aos-delay='1300'] {
  transition-delay: 0;
}

body[data-aos-delay='1300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1300'].aos-animate {
  transition-delay: 1300ms;
}

body[data-aos-duration='1350'] [data-aos], [data-aos][data-aos][data-aos-duration='1350'] {
  transition-duration: 1350ms;
}

body[data-aos-delay='1350'] [data-aos], [data-aos][data-aos][data-aos-delay='1350'] {
  transition-delay: 0;
}

body[data-aos-delay='1350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1350'].aos-animate {
  transition-delay: 1350ms;
}

body[data-aos-duration='1400'] [data-aos], [data-aos][data-aos][data-aos-duration='1400'] {
  transition-duration: 1400ms;
}

body[data-aos-delay='1400'] [data-aos], [data-aos][data-aos][data-aos-delay='1400'] {
  transition-delay: 0;
}

body[data-aos-delay='1400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1400'].aos-animate {
  transition-delay: 1400ms;
}

body[data-aos-duration='1450'] [data-aos], [data-aos][data-aos][data-aos-duration='1450'] {
  transition-duration: 1450ms;
}

body[data-aos-delay='1450'] [data-aos], [data-aos][data-aos][data-aos-delay='1450'] {
  transition-delay: 0;
}

body[data-aos-delay='1450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1450'].aos-animate {
  transition-delay: 1450ms;
}

body[data-aos-duration='1500'] [data-aos], [data-aos][data-aos][data-aos-duration='1500'] {
  transition-duration: 1500ms;
}

body[data-aos-delay='1500'] [data-aos], [data-aos][data-aos][data-aos-delay='1500'] {
  transition-delay: 0;
}

body[data-aos-delay='1500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1500'].aos-animate {
  transition-delay: 1500ms;
}

body[data-aos-duration='1550'] [data-aos], [data-aos][data-aos][data-aos-duration='1550'] {
  transition-duration: 1550ms;
}

body[data-aos-delay='1550'] [data-aos], [data-aos][data-aos][data-aos-delay='1550'] {
  transition-delay: 0;
}

body[data-aos-delay='1550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1550'].aos-animate {
  transition-delay: 1550ms;
}

body[data-aos-duration='1600'] [data-aos], [data-aos][data-aos][data-aos-duration='1600'] {
  transition-duration: 1600ms;
}

body[data-aos-delay='1600'] [data-aos], [data-aos][data-aos][data-aos-delay='1600'] {
  transition-delay: 0;
}

body[data-aos-delay='1600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1600'].aos-animate {
  transition-delay: 1600ms;
}

body[data-aos-duration='1650'] [data-aos], [data-aos][data-aos][data-aos-duration='1650'] {
  transition-duration: 1650ms;
}

body[data-aos-delay='1650'] [data-aos], [data-aos][data-aos][data-aos-delay='1650'] {
  transition-delay: 0;
}

body[data-aos-delay='1650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1650'].aos-animate {
  transition-delay: 1650ms;
}

body[data-aos-duration='1700'] [data-aos], [data-aos][data-aos][data-aos-duration='1700'] {
  transition-duration: 1700ms;
}

body[data-aos-delay='1700'] [data-aos], [data-aos][data-aos][data-aos-delay='1700'] {
  transition-delay: 0;
}

body[data-aos-delay='1700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1700'].aos-animate {
  transition-delay: 1700ms;
}

body[data-aos-duration='1750'] [data-aos], [data-aos][data-aos][data-aos-duration='1750'] {
  transition-duration: 1750ms;
}

body[data-aos-delay='1750'] [data-aos], [data-aos][data-aos][data-aos-delay='1750'] {
  transition-delay: 0;
}

body[data-aos-delay='1750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1750'].aos-animate {
  transition-delay: 1750ms;
}

body[data-aos-duration='1800'] [data-aos], [data-aos][data-aos][data-aos-duration='1800'] {
  transition-duration: 1800ms;
}

body[data-aos-delay='1800'] [data-aos], [data-aos][data-aos][data-aos-delay='1800'] {
  transition-delay: 0;
}

body[data-aos-delay='1800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1800'].aos-animate {
  transition-delay: 1800ms;
}

body[data-aos-duration='1850'] [data-aos], [data-aos][data-aos][data-aos-duration='1850'] {
  transition-duration: 1850ms;
}

body[data-aos-delay='1850'] [data-aos], [data-aos][data-aos][data-aos-delay='1850'] {
  transition-delay: 0;
}

body[data-aos-delay='1850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1850'].aos-animate {
  transition-delay: 1850ms;
}

body[data-aos-duration='1900'] [data-aos], [data-aos][data-aos][data-aos-duration='1900'] {
  transition-duration: 1900ms;
}

body[data-aos-delay='1900'] [data-aos], [data-aos][data-aos][data-aos-delay='1900'] {
  transition-delay: 0;
}

body[data-aos-delay='1900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1900'].aos-animate {
  transition-delay: 1900ms;
}

body[data-aos-duration='1950'] [data-aos], [data-aos][data-aos][data-aos-duration='1950'] {
  transition-duration: 1950ms;
}

body[data-aos-delay='1950'] [data-aos], [data-aos][data-aos][data-aos-delay='1950'] {
  transition-delay: 0;
}

body[data-aos-delay='1950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='1950'].aos-animate {
  transition-delay: 1950ms;
}

body[data-aos-duration='2000'] [data-aos], [data-aos][data-aos][data-aos-duration='2000'] {
  transition-duration: 2000ms;
}

body[data-aos-delay='2000'] [data-aos], [data-aos][data-aos][data-aos-delay='2000'] {
  transition-delay: 0;
}

body[data-aos-delay='2000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2000'].aos-animate {
  transition-delay: 2000ms;
}

body[data-aos-duration='2050'] [data-aos], [data-aos][data-aos][data-aos-duration='2050'] {
  transition-duration: 2050ms;
}

body[data-aos-delay='2050'] [data-aos], [data-aos][data-aos][data-aos-delay='2050'] {
  transition-delay: 0;
}

body[data-aos-delay='2050'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2050'].aos-animate {
  transition-delay: 2050ms;
}

body[data-aos-duration='2100'] [data-aos], [data-aos][data-aos][data-aos-duration='2100'] {
  transition-duration: 2100ms;
}

body[data-aos-delay='2100'] [data-aos], [data-aos][data-aos][data-aos-delay='2100'] {
  transition-delay: 0;
}

body[data-aos-delay='2100'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2100'].aos-animate {
  transition-delay: 2100ms;
}

body[data-aos-duration='2150'] [data-aos], [data-aos][data-aos][data-aos-duration='2150'] {
  transition-duration: 2150ms;
}

body[data-aos-delay='2150'] [data-aos], [data-aos][data-aos][data-aos-delay='2150'] {
  transition-delay: 0;
}

body[data-aos-delay='2150'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2150'].aos-animate {
  transition-delay: 2150ms;
}

body[data-aos-duration='2200'] [data-aos], [data-aos][data-aos][data-aos-duration='2200'] {
  transition-duration: 2200ms;
}

body[data-aos-delay='2200'] [data-aos], [data-aos][data-aos][data-aos-delay='2200'] {
  transition-delay: 0;
}

body[data-aos-delay='2200'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2200'].aos-animate {
  transition-delay: 2200ms;
}

body[data-aos-duration='2250'] [data-aos], [data-aos][data-aos][data-aos-duration='2250'] {
  transition-duration: 2250ms;
}

body[data-aos-delay='2250'] [data-aos], [data-aos][data-aos][data-aos-delay='2250'] {
  transition-delay: 0;
}

body[data-aos-delay='2250'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2250'].aos-animate {
  transition-delay: 2250ms;
}

body[data-aos-duration='2300'] [data-aos], [data-aos][data-aos][data-aos-duration='2300'] {
  transition-duration: 2300ms;
}

body[data-aos-delay='2300'] [data-aos], [data-aos][data-aos][data-aos-delay='2300'] {
  transition-delay: 0;
}

body[data-aos-delay='2300'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2300'].aos-animate {
  transition-delay: 2300ms;
}

body[data-aos-duration='2350'] [data-aos], [data-aos][data-aos][data-aos-duration='2350'] {
  transition-duration: 2350ms;
}

body[data-aos-delay='2350'] [data-aos], [data-aos][data-aos][data-aos-delay='2350'] {
  transition-delay: 0;
}

body[data-aos-delay='2350'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2350'].aos-animate {
  transition-delay: 2350ms;
}

body[data-aos-duration='2400'] [data-aos], [data-aos][data-aos][data-aos-duration='2400'] {
  transition-duration: 2400ms;
}

body[data-aos-delay='2400'] [data-aos], [data-aos][data-aos][data-aos-delay='2400'] {
  transition-delay: 0;
}

body[data-aos-delay='2400'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2400'].aos-animate {
  transition-delay: 2400ms;
}

body[data-aos-duration='2450'] [data-aos], [data-aos][data-aos][data-aos-duration='2450'] {
  transition-duration: 2450ms;
}

body[data-aos-delay='2450'] [data-aos], [data-aos][data-aos][data-aos-delay='2450'] {
  transition-delay: 0;
}

body[data-aos-delay='2450'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2450'].aos-animate {
  transition-delay: 2450ms;
}

body[data-aos-duration='2500'] [data-aos], [data-aos][data-aos][data-aos-duration='2500'] {
  transition-duration: 2500ms;
}

body[data-aos-delay='2500'] [data-aos], [data-aos][data-aos][data-aos-delay='2500'] {
  transition-delay: 0;
}

body[data-aos-delay='2500'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2500'].aos-animate {
  transition-delay: 2500ms;
}

body[data-aos-duration='2550'] [data-aos], [data-aos][data-aos][data-aos-duration='2550'] {
  transition-duration: 2550ms;
}

body[data-aos-delay='2550'] [data-aos], [data-aos][data-aos][data-aos-delay='2550'] {
  transition-delay: 0;
}

body[data-aos-delay='2550'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2550'].aos-animate {
  transition-delay: 2550ms;
}

body[data-aos-duration='2600'] [data-aos], [data-aos][data-aos][data-aos-duration='2600'] {
  transition-duration: 2600ms;
}

body[data-aos-delay='2600'] [data-aos], [data-aos][data-aos][data-aos-delay='2600'] {
  transition-delay: 0;
}

body[data-aos-delay='2600'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2600'].aos-animate {
  transition-delay: 2600ms;
}

body[data-aos-duration='2650'] [data-aos], [data-aos][data-aos][data-aos-duration='2650'] {
  transition-duration: 2650ms;
}

body[data-aos-delay='2650'] [data-aos], [data-aos][data-aos][data-aos-delay='2650'] {
  transition-delay: 0;
}

body[data-aos-delay='2650'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2650'].aos-animate {
  transition-delay: 2650ms;
}

body[data-aos-duration='2700'] [data-aos], [data-aos][data-aos][data-aos-duration='2700'] {
  transition-duration: 2700ms;
}

body[data-aos-delay='2700'] [data-aos], [data-aos][data-aos][data-aos-delay='2700'] {
  transition-delay: 0;
}

body[data-aos-delay='2700'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2700'].aos-animate {
  transition-delay: 2700ms;
}

body[data-aos-duration='2750'] [data-aos], [data-aos][data-aos][data-aos-duration='2750'] {
  transition-duration: 2750ms;
}

body[data-aos-delay='2750'] [data-aos], [data-aos][data-aos][data-aos-delay='2750'] {
  transition-delay: 0;
}

body[data-aos-delay='2750'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2750'].aos-animate {
  transition-delay: 2750ms;
}

body[data-aos-duration='2800'] [data-aos], [data-aos][data-aos][data-aos-duration='2800'] {
  transition-duration: 2800ms;
}

body[data-aos-delay='2800'] [data-aos], [data-aos][data-aos][data-aos-delay='2800'] {
  transition-delay: 0;
}

body[data-aos-delay='2800'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2800'].aos-animate {
  transition-delay: 2800ms;
}

body[data-aos-duration='2850'] [data-aos], [data-aos][data-aos][data-aos-duration='2850'] {
  transition-duration: 2850ms;
}

body[data-aos-delay='2850'] [data-aos], [data-aos][data-aos][data-aos-delay='2850'] {
  transition-delay: 0;
}

body[data-aos-delay='2850'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2850'].aos-animate {
  transition-delay: 2850ms;
}

body[data-aos-duration='2900'] [data-aos], [data-aos][data-aos][data-aos-duration='2900'] {
  transition-duration: 2900ms;
}

body[data-aos-delay='2900'] [data-aos], [data-aos][data-aos][data-aos-delay='2900'] {
  transition-delay: 0;
}

body[data-aos-delay='2900'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2900'].aos-animate {
  transition-delay: 2900ms;
}

body[data-aos-duration='2950'] [data-aos], [data-aos][data-aos][data-aos-duration='2950'] {
  transition-duration: 2950ms;
}

body[data-aos-delay='2950'] [data-aos], [data-aos][data-aos][data-aos-delay='2950'] {
  transition-delay: 0;
}

body[data-aos-delay='2950'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='2950'].aos-animate {
  transition-delay: 2950ms;
}

body[data-aos-duration='3000'] [data-aos], [data-aos][data-aos][data-aos-duration='3000'] {
  transition-duration: 3000ms;
}

body[data-aos-delay='3000'] [data-aos], [data-aos][data-aos][data-aos-delay='3000'] {
  transition-delay: 0;
}

body[data-aos-delay='3000'] [data-aos].aos-animate, [data-aos][data-aos][data-aos-delay='3000'].aos-animate {
  transition-delay: 3000ms;
}

body[data-aos-easing="linear"] [data-aos], [data-aos][data-aos][data-aos-easing="linear"] {
  transition-timing-function: cubic-bezier(0.25, 0.25, 0.75, 0.75);
}

body[data-aos-easing="ease"] [data-aos], [data-aos][data-aos][data-aos-easing="ease"] {
  transition-timing-function: cubic-bezier(0.25, 0.1, 0.25, 1);
}

body[data-aos-easing="ease-in"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in"] {
  transition-timing-function: cubic-bezier(0.42, 0, 1, 1);
}

body[data-aos-easing="ease-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out"] {
  transition-timing-function: cubic-bezier(0, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-out"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out"] {
  transition-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}

body[data-aos-easing="ease-in-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-back"] {
  transition-timing-function: cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

body[data-aos-easing="ease-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-back"] {
  transition-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

body[data-aos-easing="ease-in-out-back"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-back"] {
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

body[data-aos-easing="ease-in-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-sine"] {
  transition-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
}

body[data-aos-easing="ease-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-sine"] {
  transition-timing-function: cubic-bezier(0.39, 0.575, 0.565, 1);
}

body[data-aos-easing="ease-in-out-sine"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-sine"] {
  transition-timing-function: cubic-bezier(0.445, 0.05, 0.55, 0.95);
}

body[data-aos-easing="ease-in-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quad"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quad"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quad"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quad"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-cubic"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-cubic"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-cubic"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-cubic"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

body[data-aos-easing="ease-in-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-quart"] {
  transition-timing-function: cubic-bezier(0.55, 0.085, 0.68, 0.53);
}

body[data-aos-easing="ease-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-out-quart"] {
  transition-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

body[data-aos-easing="ease-in-out-quart"] [data-aos], [data-aos][data-aos][data-aos-easing="ease-in-out-quart"] {
  transition-timing-function: cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

/**
 * Fade animations:
 * fade
 * fade-up, fade-down, fade-left, fade-right
 * fade-up-right, fade-up-left, fade-down-right, fade-down-left
 */
[data-aos^='fade'][data-aos^='fade'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='fade'][data-aos^='fade'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

[data-aos='fade-up'] {
  transform: translate3d(0, 100px, 0);
}

[data-aos='fade-down'] {
  transform: translate3d(0, -100px, 0);
}

[data-aos='fade-right'] {
  transform: translate3d(-100px, 0, 0);
}

[data-aos='fade-left'] {
  transform: translate3d(100px, 0, 0);
}

[data-aos='fade-up-right'] {
  transform: translate3d(-100px, 100px, 0);
}

[data-aos='fade-up-left'] {
  transform: translate3d(100px, 100px, 0);
}

[data-aos='fade-down-right'] {
  transform: translate3d(-100px, -100px, 0);
}

[data-aos='fade-down-left'] {
  transform: translate3d(100px, -100px, 0);
}

/**
 * Zoom animations:
 * zoom-in, zoom-in-up, zoom-in-down, zoom-in-left, zoom-in-right
 * zoom-out, zoom-out-up, zoom-out-down, zoom-out-left, zoom-out-right
 */
[data-aos^='zoom'][data-aos^='zoom'] {
  opacity: 0;
  transition-property: opacity, transform;
}

[data-aos^='zoom'][data-aos^='zoom'].aos-animate {
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(1);
}

[data-aos='zoom-in'] {
  transform: scale(0.6);
}

[data-aos='zoom-in-up'] {
  transform: translate3d(0, 100px, 0) scale(0.6);
}

[data-aos='zoom-in-down'] {
  transform: translate3d(0, -100px, 0) scale(0.6);
}

[data-aos='zoom-in-right'] {
  transform: translate3d(-100px, 0, 0) scale(0.6);
}

[data-aos='zoom-in-left'] {
  transform: translate3d(100px, 0, 0) scale(0.6);
}

[data-aos='zoom-out'] {
  transform: scale(1.2);
}

[data-aos='zoom-out-up'] {
  transform: translate3d(0, 100px, 0) scale(1.2);
}

[data-aos='zoom-out-down'] {
  transform: translate3d(0, -100px, 0) scale(1.2);
}

[data-aos='zoom-out-right'] {
  transform: translate3d(-100px, 0, 0) scale(1.2);
}

[data-aos='zoom-out-left'] {
  transform: translate3d(100px, 0, 0) scale(1.2);
}

/**
 * Slide animations
 */
[data-aos^='slide'][data-aos^='slide'] {
  transition-property: transform;
}

[data-aos^='slide'][data-aos^='slide'].aos-animate {
  transform: translate3d(0, 0, 0);
}

[data-aos='slide-up'] {
  transform: translate3d(0, 100%, 0);
}

[data-aos='slide-down'] {
  transform: translate3d(0, -100%, 0);
}

[data-aos='slide-right'] {
  transform: translate3d(-100%, 0, 0);
}

[data-aos='slide-left'] {
  transform: translate3d(100%, 0, 0);
}

/**
 * Flip animations:
 * flip-left, flip-right, flip-up, flip-down
 */
[data-aos^='flip'][data-aos^='flip'] {
  backface-visibility: hidden;
  transition-property: transform;
}

[data-aos='flip-left'] {
  transform: perspective(2500px) rotateY(-100deg);
}

[data-aos='flip-left'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-right'] {
  transform: perspective(2500px) rotateY(100deg);
}

[data-aos='flip-right'].aos-animate {
  transform: perspective(2500px) rotateY(0);
}

[data-aos='flip-up'] {
  transform: perspective(2500px) rotateX(-100deg);
}

[data-aos='flip-up'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

[data-aos='flip-down'] {
  transform: perspective(2500px) rotateX(100deg);
}

[data-aos='flip-down'].aos-animate {
  transform: perspective(2500px) rotateX(0);
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

*, *:before, *:after {
  box-sizing: border-box;
}

html {
  height: 100%;
  background-color: #fff;
}

body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
  background-color: #fff;
}

.MainWrapper {
  position: relative;
}

img {
  max-width: 100%;
}

label,
.label {
  margin-bottom: .5rem;
  font-weight: 400;
  font-size: 1rem;
}

.color-white {
  color: #FFF;
}

.color-white:hover {
  color: #FFF;
}

.margin-top--10 {
  margin-top: 10px;
}

.margin-top--20 {
  margin-top: 20px;
}

.margin-top--30 {
  margin-top: 30px;
}

.margin-top--50 {
  margin-top: 50px;
}

.margin-bottom--5 {
  margin-bottom: 5px;
}

.margin-bottom--10 {
  margin-bottom: 10px;
}

.margin-bottom--20 {
  margin-bottom: 20px;
}

.margin-bottom--30 {
  margin-bottom: 30px;
}

.margin-bottom--50 {
  margin-bottom: 50px;
}

.margin-vertical--30 {
  margin: 30px 0;
}

.margin-vertical--50 {
  margin: 50px 0;
}

.padding-top--10 {
  padding-top: 10px;
}

.padding-top--20 {
  padding-top: 20px;
}

.padding-top--30 {
  padding-top: 30px;
}

.padding-top--50 {
  padding-top: 50px;
}

.padding-vertical--20 {
  padding-top: 20px;
  padding-bottom: 20px;
}

.padding-vertical--30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.padding-vertical--50 {
  padding-top: 50px;
  padding-bottom: 50px;
}

.padding-vertical--80 {
  padding-top: 80px;
  padding-bottom: 80px;
}

.padding-vertical--100 {
  padding-top: 100px !important;
  padding-bottom: 100px !important;
}

.padding-vertical--120 {
  padding-top: 120px !important;
  padding-bottom: 120px !important;
}

/**
 * lists
 */
ul:not(.list-unstyled) {
  padding-left: 1.3125rem;
  list-style-image: url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23f5bd52' cx='3' cy='5' r='3'/%3e%3c/svg%3e");
}

ol:not(.list-unstyled) {
  padding-left: 1.3125rem;
}

body {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -moz-font-feature-settings: "liga" on;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-top: 1.5rem;
}

h1:first-child, .h1:first-child,
h2:first-child, .h2:first-child,
h3:first-child, .h3:first-child,
h4:first-child, .h4:first-child,
h5:first-child, .h5:first-child,
h6:first-child, .h6:first-child {
  margin-top: 0;
}

h1:last-child, .h1:last-child,
h2:last-child, .h2:last-child,
h3:last-child, .h3:last-child,
h4:last-child, .h4:last-child,
h5:last-child, .h5:last-child,
h6:last-child, .h6:last-child {
  margin-bottom: 0;
}

h5, .h5,
h6, .h6 {
  font-family: "Benton Sans", "Libre Franklin", sans-serif;
  font-weight: 700;
}

.display-1,
.display-2,
.display-3,
.display-4 {
  letter-spacing: -.06em !important;
}

@media (max-width: 1400px) {
  .display-1 {
    font-size: 4rem;
  }
  .display-2 {
    font-size: 3.375rem;
  }
  .display-3 {
    font-size: 2.75rem;
  }
  .display-4 {
    font-size: 1.125rem;
  }
}

@media (max-width: 1199.98px) {
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-top: 1rem;
  }
  h1:first-child, .h1:first-child,
  h2:first-child, .h2:first-child,
  h3:first-child, .h3:first-child,
  h4:first-child, .h4:first-child,
  h5:first-child, .h5:first-child,
  h6:first-child, .h6:first-child {
    margin-top: 0;
  }
  .display-1 {
    font-size: 3rem;
  }
  .display-2 {
    font-size: 3.375rem;
  }
  .display-3 {
    font-size: 2.75rem;
  }
  .display-4 {
    font-size: 1.125rem;
  }
}

@media (max-width: 767.98px) {
  .display-1 {
    font-size: 2rem;
  }
  .display-2 {
    font-size: 1.5rem;
  }
  .display-3 {
    font-size: 1.25rem;
  }
  .display-4 {
    font-size: 1.125rem;
  }
  h1, .h1 {
    font-size: 1.5rem;
  }
  h2, .h2 {
    font-size: 1.3125rem;
  }
  h3, .h3 {
    font-size: 1.25rem;
  }
}

.Heading {
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 0.04em;
}

a {
  transition: color 250ms;
}

button,
input {
  overflow: visible;
  outline: none;
}

@font-face {
  font-family: "refis";
  src: url("../fonts/refis.eot?uh4n8r");
  src: url("../fonts/refis.eot?uh4n8r#iefix") format("embedded-opentype"), url("../fonts/refis.ttf?uh4n8r") format("truetype"), url("../fonts/refis.woff?uh4n8r") format("woff"), url("../fonts/refis.svg?uh4n8r#refis") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "refis" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-credit-card:before {
  content: "";
}

.icon-calculator:before {
  content: "";
}

.icon-location:before {
  content: "";
}

.icon-mail:before {
  content: "";
}

.icon-phone:before {
  content: "";
}

.icon-cancel:before {
  content: "";
}

.icon-check:before {
  content: "";
}

.icon-clock:before {
  content: "";
}

.icon-cross:before {
  content: "";
}

.icon-hourglass:before {
  content: "";
}

.icon-star:before {
  content: "";
}

.icon-suitcase:before {
  content: "";
}

.btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.625rem 1.25rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  background-color: transparent;
}

@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn:hover, .btn:focus {
  text-decoration: none;
}

.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.25);
}

.btn.disabled, .btn:disabled {
  opacity: 0.65;
}

.btn:not(:disabled):not(.disabled) {
  cursor: pointer;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-primary,
.btn-secondary {
  position: relative;
  z-index: 0;
  overflow: hidden;
  border-color: transparent;
  background: none;
}

.btn-primary:before,
.btn-secondary:before {
  content: '';
  display: block;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 200%;
  height: 100%;
  transform: translateX(-50%);
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.btn-primary:hover:before, .btn-primary:focus:before,
.btn-secondary:hover:before,
.btn-secondary:focus:before {
  transform: translateX(0);
}

.btn-primary {
  color: #13131e;
  background-color: #f5bd52;
  border-color: #f5bd52;
}

.btn-primary:hover {
  color: #13131e;
  background-color: #f3af2e;
  border-color: #f2ab22;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 164, 74, 0.5);
}

.btn-primary.disabled, .btn-primary:disabled {
  color: #13131e;
  background-color: #f5bd52;
  border-color: #f5bd52;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
  color: #13131e;
  background-color: #f2ab22;
  border-color: #f2a616;
}

.btn-primary:not(:disabled):not(.disabled):active:focus, .btn-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(211, 164, 74, 0.5);
}

.btn-primary:before {
  background: linear-gradient(to right, #f8d085, #f5bd52 50%, #f5bd52 50%, #f8d085);
}

.btn-secondary {
  color: #fff;
  background-color: #082948;
  border-color: #082948;
}

.btn-secondary:hover {
  color: #fff;
  background-color: #041526;
  border-color: #030f1a;
}

.btn-secondary:focus, .btn-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 73, 99, 0.5);
}

.btn-secondary.disabled, .btn-secondary:disabled {
  color: #fff;
  background-color: #082948;
  border-color: #082948;
}

.btn-secondary:not(:disabled):not(.disabled):active, .btn-secondary:not(:disabled):not(.disabled).active,
.show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #030f1a;
  border-color: #02080f;
}

.btn-secondary:not(:disabled):not(.disabled):active:focus, .btn-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(45, 73, 99, 0.5);
}

.btn-secondary:before {
  background-image: linear-gradient(45deg, #082948, #040a18 50%, #040a18 50%, #082948);
}

.btn-white {
  color: #13131e;
  background-color: #fff;
  border-color: #fff;
  color: #f5bd52;
}

.btn-white:hover {
  color: #13131e;
  background-color: #ececec;
  border-color: #e6e5e5;
}

.btn-white:focus, .btn-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 220, 221, 0.5);
}

.btn-white.disabled, .btn-white:disabled {
  color: #13131e;
  background-color: #fff;
  border-color: #fff;
}

.btn-white:not(:disabled):not(.disabled):active, .btn-white:not(:disabled):not(.disabled).active,
.show > .btn-white.dropdown-toggle {
  color: #13131e;
  background-color: #e6e5e5;
  border-color: #dfdfdf;
}

.btn-white:not(:disabled):not(.disabled):active:focus, .btn-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(220, 220, 221, 0.5);
}

.btn-outline-primary {
  color: #f5bd52;
  border-color: #f5bd52;
}

.btn-outline-primary:hover {
  color: #13131e;
  background-color: #f5bd52;
  border-color: #f5bd52;
}

.btn-outline-primary:focus, .btn-outline-primary.focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.5);
}

.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: #f5bd52;
  background-color: transparent;
}

.btn-outline-primary:not(:disabled):not(.disabled):active, .btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  color: #13131e;
  background-color: #f5bd52;
  border-color: #f5bd52;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus, .btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(245, 189, 82, 0.5);
}

.btn-outline-secondary {
  color: #082948;
  border-color: #082948;
}

.btn-outline-secondary:hover {
  color: #fff;
  background-color: #082948;
  border-color: #082948;
}

.btn-outline-secondary:focus, .btn-outline-secondary.focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 41, 72, 0.5);
}

.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  color: #082948;
  background-color: transparent;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active,
.show > .btn-outline-secondary.dropdown-toggle {
  color: #fff;
  background-color: #082948;
  border-color: #082948;
}

.btn-outline-secondary:not(:disabled):not(.disabled):active:focus, .btn-outline-secondary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(8, 41, 72, 0.5);
}

.btn-outline-white {
  color: #fff;
  border-color: #fff;
}

.btn-outline-white:hover {
  color: #13131e;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:focus, .btn-outline-white.focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
  color: #fff;
  background-color: transparent;
}

.btn-outline-white:not(:disabled):not(.disabled):active, .btn-outline-white:not(:disabled):not(.disabled).active,
.show > .btn-outline-white.dropdown-toggle {
  color: #13131e;
  background-color: #fff;
  border-color: #fff;
}

.btn-outline-white:not(:disabled):not(.disabled):active:focus, .btn-outline-white:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-white.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.5);
}

.btn-link {
  padding: 0;
  color: #f5bd52;
  font-family: "Benton Sans", "Libre Franklin", sans-serif;
  background-color: transparent;
}

.btn-link:hover {
  color: #eda00e;
  text-decoration: underline;
  background-color: transparent;
  border-color: transparent;
}

.btn-link:focus, .btn-link.focus {
  text-decoration: underline;
  border-color: transparent;
  box-shadow: none;
}

.btn-link:disabled, .btn-link.disabled {
  color: #5a6067;
  pointer-events: none;
}

.btn-link-default {
  font-size: 1em;
  text-transform: none;
  letter-spacing: 0;
}

.btn-link-primary {
  color: #f5bd52;
  font-size: 0.875rem;
  text-transform: none;
  letter-spacing: 0;
  text-decoration: underline;
}

.btn-shadow {
  box-shadow: 0 2px 36px rgba(245, 189, 82, 0.32);
}

.btn-lg {
  padding: 0.9375rem 2rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-sm {
  padding: 0.375rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0;
}

.btn-wide {
  padding-right: 2.5rem;
  padding-left: 2.5rem;
}

.btn-narrow {
  padding-right: 1rem;
  padding-left: 1rem;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-block + .btn-block {
  margin-top: 0.5rem;
}

input[type="submit"].btn-block,
input[type="reset"].btn-block,
input[type="button"].btn-block {
  width: 100%;
}

.btn-ui {
  padding: 0.25rem 0.5625rem;
  font-size: 1.25rem;
}

.btn-ui.btn-sm {
  padding: 0rem 0.5rem;
}

.btn-ui.btn-lg {
  padding: 0.5625rem 0.875rem;
}

.btn-icon-right {
  margin-left: 0.375rem;
}

.btn-icon-left {
  margin-right: 0.375rem;
}

.btn-collapse[aria-expanded="false"] .btn-collapse-active {
  display: none;
}

.btn-collapse[aria-expanded="true"] .btn-collapse-default {
  display: none;
}

.btn-collapse[aria-expanded="true"] .btn-collapse-active {
  display: inline;
}

.Logo {
  display: inline-block;
}

.Logo-image {
  display: block;
  width: 7rem;
  height: 1.625rem;
  background: url("../img/logo-refis-flow.svg") 50% 50%/contain no-repeat;
  transition: background-image 250ms ease-in-out;
}

.Hamburger {
  display: inline-block;
  width: 1.5rem;
  transition: transform 250ms;
}

.Hamburger-bar {
  height: 0.1875rem;
  border-radius: .5rem;
  background-color: currentColor;
  transition: transform 250ms, opacity 250ms;
}

.Hamburger-bar + .Hamburger-bar {
  margin-top: .25rem;
}

.is-active .Hamburger {
  transform: rotate(90deg);
}

.is-active .Hamburger-bar:nth-child(1) {
  transform: translateY(0.4375rem) rotate(45deg);
}

.is-active .Hamburger-bar:nth-child(2) {
  transform: scaleX(0);
  opacity: 0;
}

.is-active .Hamburger-bar:nth-child(3) {
  transform: translateY(-0.4375rem) rotate(-45deg);
}

.Arrow {
  position: relative;
  display: inline-block;
  width: 2rem;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.Arrow::before, .Arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 1px;
  display: block;
  width: 0.375rem;
  border-bottom: 2px solid;
  margin-top: -1px;
  transform-origin: center right;
}

.Arrow::before {
  transform: rotate(45deg);
}

.Arrow::after {
  transform: rotate(-45deg);
}

.Arrow-bar {
  display: block;
  border-bottom: 2px solid;
  border-radius: 0 .5rem .5rem 0;
}

.Arrow--left {
  transform: rotate(180deg);
}

.Arrow--down {
  transform: rotate(90deg);
}

.Arrow--up {
  transform: rotate(-90deg);
}

.ContactBox {
  width: 27rem;
  max-width: 100%;
  padding: 3rem 2.5rem 2.5rem;
  margin: 0 auto;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.16);
}

.ContactBox-title {
  margin-bottom: 2.5rem;
}

.ContactBox-submit {
  margin-top: 2rem;
}

@media (max-width: 767.98px) {
  .ContactBox {
    padding: 1rem;
  }
}

.Video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background: #919ba2;
}

.Video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Carousel {
  padding-left: 3rem;
  padding-right: 3rem;
}

.Carousel-prev,
.Carousel-next {
  color: #13131e;
  width: 3rem;
  height: auto;
  padding: 2rem 0;
  font-size: 1rem;
  line-height: 1;
  opacity: .25;
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
}

.Carousel-prev::before,
.Carousel-next::before {
  content: none;
  display: none;
}

.Carousel-prev:focus,
.Carousel-next:focus {
  color: #13131e;
}

.Carousel-prev:hover,
.Carousel-next:hover {
  color: #13131e;
  opacity: 1;
}

.Carousel-prev:hover .Arrow-bar,
.Carousel-next:hover .Arrow-bar {
  transform: scaleX(1.3);
}

.Carousel-prev .Arrow,
.Carousel-next .Arrow {
  display: block;
  margin: 0 auto;
}

.Carousel-prev .Arrow-bar,
.Carousel-next .Arrow-bar {
  transition: all 0.5s cubic-bezier(0.23, 1, 0.32, 1);
  transform-origin: right center;
}

.Carousel-prev {
  left: 0;
}

.Carousel-next {
  right: 0;
}

@media (min-width: 768px) {
  .Carousel {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .Carousel-prev,
  .Carousel-next {
    color: #13131e;
    width: 4rem;
  }
}

.Range > .row {
  align-items: center;
}

.Range-control {
  display: block;
}

.Range-value {
  padding: 0;
  width: 3rem;
  color: #f5bd52;
}

.Range-value:disabled, .Range-value[readonly] {
  background: none;
}

.CurrencyInput {
  position: relative;
}

.CurrencyInput-input {
  padding-right: 3.5rem;
}

.CurrencyInput-currency {
  position: absolute;
  top: 50%;
  right: 1rem;
  margin-top: 1px;
  color: #f5bd52;
  transform: translateY(-50%);
}

.Hero-bg, .Section-bg,
.Hero-overlay,
.Section-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.Hero-bg, .Section-bg {
  z-index: -10;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 250ms ease-in-out;
}


.Hero-overlay,
.Section-overlay {
  z-index: 0;
}

.Hero {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 25rem;
  padding: 9.75rem 0 6rem;
  background: #082948;
  color: #fff;
}

.Hero > .container {
  position: relative;
}

.Hero-overlay {
  background: linear-gradient(45deg, #040a18, #082948);
  opacity: .85;
}

.Hero-inner {
  padding: 4rem 0;
}

.Hero-title {
  color: inherit;
  margin-bottom: 2.25rem;
}

.HeroBenefits-tiles {
  position: relative;
  top: -4rem;
}

.HeroContact {
  position: relative;
  z-index: 1;
  padding: 4rem 0;
}

.HeroContact-image {
  width: 32.5rem;
  margin-bottom: -4rem;
}

.HeroContact-box {
  margin-top: -9rem;
  margin-bottom: -7rem;
}

@media (max-width: 991.98px) {
  .HeroContact-image {
    margin-bottom: 0;
  }
  .HeroContact-box {
    margin-top: 0;
  }
}

.Hero--homepage {
  min-height: 34rem;
}

.Hero--homepage .Hero-overlay {
  background: #082948;
  opacity: .5;
}

@media (max-height: 767.98px) {
  .Hero--homepage {
    min-height: 26rem;
  }
}

.Hero--demo {
  padding-bottom: 0;
}

@media (max-width: 991.98px) {
  .Hero--demo .Hero-title {
    margin-bottom: 4rem;
  }
}

.HeroFeatures {
  padding: 3rem 0;
}

.HeroFeatures-image {
  width: 37.5rem;
  margin-top: -10rem;
  margin-bottom: -4rem;
}

@media (max-width: 767.98px) {
  .HeroFeatures-image {
    margin-bottom: -2rem;
  }
}

.Section {
  position: relative;
  padding: 5rem 0;
}

.Section > .container {
  position: relative;
}

.Section.Asset-section {
  padding: 6rem 0;
}

@media (max-height: 767.98px) {
  .Section {
    padding: 4rem 0;
  }
}

.Section-overlay {
  background: rgba(255, 255, 255, 0.9);
}

.Section-header {
  margin-bottom: 2.5rem;
}

.Section-title {
  font-weight: 300;
  color: #082948;
}

.Section-title:after {
  content: '';
  display: block;
  width: 4rem;
  margin: 1.5rem auto 0;
  border-bottom: 2px solid;
}

.Section-text:not(:last-child) {
  margin-bottom: 2.5rem;
}

.Section--sm {
  padding: 4rem 0;
}

.Section--dark {
  background-image: linear-gradient(45deg, #040a18, #082948);
  background-color: #082948;
}

.Section--light {
  background: #f1f1f1;
}

.CheckList {
  padding-left: 2rem;
  margin-bottom: 0;
}

.CheckList > li {
  position: relative;
}

.CheckList > li:not(:last-child) {
  margin-bottom: .5rem;
}

.CheckList > li:before {
  content: "";
  position: absolute;
  left: -2rem;
  top: 0;
  font-size: 1.25rem;
  font-family: "refis" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.Map {
  min-height: 22.1875rem;
  background-color: #e8e8e8;
}

.Map::before {
  content: '';
  display: block;
  padding-bottom: 27.34375%;
}

.Pricing-item {
  position: relative;
}

.Pricing-body {
  padding: 2rem 1rem;
  border: 1px solid #082948;
  margin-bottom: 1rem;
}

.Pricing-badge {
  position: absolute;
  top: 0;
  padding: 0.375rem 2rem;
  transform: translate(-50%, -50%);
  border-radius: 999px;
  font-size: 0.8125rem;
}

.Pricing-badge:after {
  content: '\02605';
  position: absolute;
  margin-left: .5rem;
}

.Pricing-title {
  margin-top: 0;
  font-size: 1.5625rem;
}

.Pricing-features {
  margin-bottom: 0;
}

.Pricing-features:before {
  content: '';
  display: block;
  width: 8.25rem;
  margin: 1rem auto;
  border-bottom: 1px solid #082948;
}

.Pricing-features > li {
  padding: .5rem 0;
}

.Pricing-label {
  font-size: 0.85rem;
}

@media (min-width: 992px) {
  .Pricing {
    position: relative;
    padding-left: 16rem;
  }
  .Pricing-col {
    position: static;
  }
  .Pricing-col:first-child .Pricing-label {
    position: absolute;
    left: -16rem;
  }
  .Pricing-col:not(:first-child) .Pricing-label {
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap;
    border: 0;
  }
}

@media (max-width: 991.98px) {
  .Pricing-col {
    margin-bottom: 2rem;
  }
  .Pricing-features > li {
    display: flex;
  }
  .Pricing-label {
    flex: 1 1 auto;
    align-items: center;
    text-align: left;
    padding-right: 1rem;
  }
}

.Steps {
  margin-bottom: 2.5rem;
}

.Step {
  height: 100%;
  position: relative;
  background: #fff;
  box-shadow: 0 0 1rem rgba(0, 0, 0, 0.05);
  transition: all .3s ease;
}

.Step-arrow {
  position: absolute;
  z-index: 10;
  top: 3.75rem;
  right: -.6rem;
}

.Step-inner {
  position: relative;
  z-index: 0;
  height: 100%;
  padding: 3rem 2rem 2.5rem;
  overflow: hidden;
}

.Step-icon {
  position: absolute;
  z-index: -1;
  bottom: -.075em;
  right: 1.5rem;
  font-size: 9rem;
  line-height: 1;
  color: #fff;
  opacity: .35;
}

.Step-number {
  color: #f5bd52;
  margin-top: 0;
}

.Step--secondary {
  border-bottom: 4px solid #f5bd52;
}

.Step--secondary .Step-arrow {
  top: 50%;
  right: 4.5rem;
  transform: translateY(-50%);
}

.Step--secondary .Step-inner {
  padding: 1.5rem 7.75rem;
}

.Step--highlight .Step-number {
  color: #fff;
}

.Highlighted-bg {
  display: none;
  background-image: linear-gradient(to right, #f5bd52, #f8d085);
  background-color: #f5bd52;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
}

.Step-01 .Highlighted-bg {
  display: block;
}

.StepsCTA {
  margin: 2.5rem 0 -6rem;
}

@media (max-width: 767.98px) {
  .Steps-item:not(:last-child) {
    margin-bottom: 1rem;
  }
  .Step-arrow {
    top: auto;
    right: 2rem;
    bottom: -.6rem;
    transform-origin: center right;
    transform: rotate(90deg);
  }
  .Step--secondary {
    border: none;
    border-left: 0.25rem solid #f5bd52;
    text-align: left;
  }
  .Step--secondary .Step-inner {
    padding: 1.5rem 4rem 1.5rem 1.75rem;
  }
  .Step--secondary .Step-arrow {
    right: 2rem;
    bottom: auto;
    margin-top: 1rem;
    transform: rotate(90deg);
  }
}

.Targets {
  margin: 0 -1px;
}

.Targets-item {
  padding: 0 1px;
  margin-bottom: 2px;
}

.Target {
  position: relative;
  z-index: 0;
  padding: 2.5rem 2rem 2.5rem 9.375rem;
  border: 1px solid #d1d9dd;
  color: #13131e;
  font-size: 0.875rem;
  transition: background 250ms ease-in-out;
}

.Target::before {
  content: '';
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, #e8e8e8, #fff);
  opacity: 0;
  transition: opacity 250ms ease-in-out;
}

.Target:hover:before, .Target:focus-within:before {
  opacity: 1;
}

.Target:hover .Target-arrow, .Target:focus-within .Target-arrow {
  width: 3rem;
}

.Target-title a {
  color: inherit;
}

.Target-arrow {
  position: absolute;
  margin-top: .5em;
  margin-left: -4rem;
}

.Target-text p:last-child {
  margin-bottom: 0;
}

.Target-more {
  color: #f5bd52;
  font-weight: 700;
  text-decoration: underline;
}

@media (max-width: 991.98px) {
  .Target {
    padding: 2rem 1rem 2rem 5.5rem;
  }
}

.Badges {
  font-size: 0;
  margin-bottom: -.375rem;
}

.Badges-item {
  display: inline-block;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
}

.Badge {
  display: inline-block;
  padding: 0.4375em 0;
  min-width: 2em;
  border-radius: 0.1875em;
  border: 1px solid #d1d9dd;
  color: #f5bd52;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;
  transition: all 250ms;
}

.Badge--sm {
  font-size: 0.875rem;
  letter-spacing: -.03em;
}

.Badge--pill {
  border-radius: 999px;
}

.Badge--primary {
  border-color: transparent;
  background-color: #f5bd52;
  color: #fff;
}

a.Badge--primary:hover,
a.Badge--primary:focus {
  color: #fff;
  background-color: #f3af2e;
}

.Badge--transparentWhite {
  padding: .5rem 1rem;
  background: rgba(255, 255, 255, 0.2);
  border-color: transparent;
  border-radius: 0.375rem;
  font-weight: 700;
  font-size: 0.875rem;
  color: #fff;
  text-transform: none;
}

.Badge--transparentWhite:hover, .Badge--transparentWhite:focus {
  color: #fff;
}

.ContactHeading {
  padding-left: 3rem;
  margin-bottom: 1.5rem;
}

.Contact {
  display: flex;
}

.Contact + .Contact {
  margin-top: 1.5rem;
}

.Contact-icon {
  width: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.3125em;
}

.Contact-body {
  flex: 1 1 auto;
}

address.Contact-body {
  line-height: 2;
  margin-top: -0.3em;
  margin-bottom: 0;
}

@media (max-width: 767.98px) {
  .ContactSection {
    margin-bottom: 3rem;
  }
}

.Measure {
  max-width: 58.5rem;
  margin: 0 auto 0;
}

.Article {
  position: relative;
}

.Article p,
.Article ul,
.Article ol,
.Article .table-responsive,
.Article .table,
.Article table,
.Article h1, .Article .h1,
.Article h2, .Article .h2,
.Article h3, .Article .h3,
.Article h4, .Article .h4,
.Article h5, .Article .h5,
.Article h6, .Article .h6 {
  margin-bottom: 2rem;
}

.Article .table-responsive .table,
.Article .table-responsive table {
  margin-bottom: 0;
}

.Article .lead {
  color: #13131e;
  font-weight: 700;
}

.Article img,
.Article figure,
.Article .row,
.Article .row [class*="col-"] {
  page-break-inside: avoid;
  page-break-after: avoid;
}

.Article img {
  display: block;
  margin-bottom: 2rem;
}

.Article figure {
  position: relative;
  margin-bottom: 2rem;
}

.Article figure img {
  margin: 0 auto;
}

.Article figcaption {
  padding: 1.5rem;
  background: #13131e;
  color: #fff;
  font-weight: 500;
  font-size: 0.875rem;
}

.Article .figcaption-source {
  float: right;
  font-weight: 400;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
}

.ArticleContent {
  margin: 3rem 0;
}

.ArticleContent:first-child {
  margin-top: 0;
}

.ArticleNetworks {
  position: absolute;
  top: 0;
  right: -5.25rem;
}

@media (max-width: 1199.98px) {
  .ArticleNetworks {
    position: static;
    text-align: center;
  }
  .ArticleNetworks > li {
    display: inline-block;
  }
  .ArticleNetworks > li:not(:last-child) {
    margin-right: .5rem;
  }
  .Article .lead {
    font-size: 1.3125rem;
  }
}

@media (max-width: 575.98px) {
  .Article figure,
  .Article img {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .Article figure img {
    margin-left: auto;
    margin-right: auto;
  }
  .Article figcaption {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
  }
  .Article .figcaption-content {
    flex: 1 0 100%;
  }
  .Article .figcaption-source {
    order: 99;
    flex: 1 0 100%;
    margin: 1.5rem -1.5rem -1.5rem;
    padding: 1rem 1.5rem 0;
    background: #e8e8e8;
    color: #919ba2;
  }
}

.Tiles {
  justify-content: center;
}

.Tiles-item {
  flex-direction: column;
  margin-bottom: 1.5rem;
}

.Tiles-item > .Tile {
  height: 100%;
}

.Tile {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem 2rem;
  background: #fff;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.09);
  color: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  text-align: center;
  transition: background 250ms ease-in-out;
}

.Tile a {
  color: #082948;
}

.Tile a:hover {
  text-decoration: none;
}

a.Tile:hover,
a.Tile:focus,
.Tile[data-toggle="tooltip"]:hover,
.Tile[data-toggle="tooltip"]:focus {
  background: #e8e8e8;
  color: inherit;
  text-decoration: none;
}

a.Tile:hover .Tile-title,
a.Tile:focus .Tile-title,
.Tile[data-toggle="tooltip"]:hover .Tile-title,
.Tile[data-toggle="tooltip"]:focus .Tile-title {
  text-decoration: underline;
}

a.Tile:hover .Tile-title.no-underline,
a.Tile:focus .Tile-title.no-underline,
.Tile[data-toggle="tooltip"]:hover .Tile-title.no-underline,
.Tile[data-toggle="tooltip"]:focus .Tile-title.no-underline {
  text-decoration: none;
}

.Tile-icon {
  display: block;
  font-size: 3.25rem;
  margin: -.25rem auto .75rem;
}

.Tile-title {
  display: block;
  margin: auto 0;
}

.Tile-title:last-child {
  margin-bottom: auto;
}

.Tile--border {
  background: none;
  border: 1px solid;
  box-shadow: none;
}

a.Tile--border:hover,
a.Tile--border:focus,
.Tile--border[data-toggle="tooltip"]:hover,
.Tile--border[data-toggle="tooltip"]:focus {
  background: rgba(255, 255, 255, 0.1);
}

.Tile--subtle {
  box-shadow: none;
  background: none;
  border: 1px solid transparent;
  transition: border-color 250ms ease-in-out;
}

a.Tile--subtle:hover,
a.Tile--subtle:focus,
.Tile--subtle[data-toggle="tooltip"]:hover,
.Tile--subtle[data-toggle="tooltip"]:focus {
  border-color: #082948;
  background: none;
}

.Tile--square {
  position: relative;
}

.Tile--square::before {
  content: '';
  display: block;
  padding-bottom: 100%;
}

.Tile--square .Tile-inner {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
}

.Tile--square .Tile-logo {
  flex: 0 1 auto;
}

.Usage {
  overflow: hidden;
}

.Usage-mapSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 42rem;
}

.Usage-title {
  font-size: 1.875rem;
}

.Usage-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

@media (min-width: 992px) {
  .Usage-map {
    left: 50%;
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .Usage-map {
    bottom: 2rem;
    background-size: cover;
    background-position: 100% 100%;
  }
}

.Stats {
  margin-bottom: -2rem;
}

.Stats-item {
  margin-bottom: 2rem;
}

.Stat-value {
  font-size: 1.875rem;
}

.Results {
  margin-bottom: -2rem;
}

.Results-item {
  margin-bottom: 2rem;
}

.Results-tile {
  min-height: 11.25rem;
  margin-bottom: 1rem;
}

.Results-value {
  display: block;
  margin-bottom: .25rem;
  font-size: 1.875rem;
}

.Results-label {
  font-size: 0.875rem;
}

.Circle-wrap {
  position: relative;
  display: inline-block;
}

.Circle-text {
  position: absolute;
  top: 50%;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 1.125rem;
  transform: translateY(-50%);
}

.Circle-maxValueStroke {
  stroke-width: 2px;
  stroke: #e8e8e8;
}

.Circle-valueStroke {
  stroke-width: 4px;
  stroke: #f5bd52;
}

.CalcResult {
  position: relative;
  padding-left: 2.5rem;
}

.CalcResult-col {
  position: static;
}

.CalcResult-number {
  position: absolute;
  top: -.125rem;
  left: 0;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
}

.CalcResult-title {
  margin-top: 0;
}

.CalcResult-text {
  font-size: 0.875rem;
  text-align: justify;
}

.CalcResult-result {
  position: relative;
  display: block;
  padding-right: 1rem;
  font-size: 1.125rem;
}

.CalcResult-resultLarge {
  font-size: 1.5rem;
}

.CalcResult-resultSmall {
  font-size: 0.8125rem;
  line-height: 1.2;
}

.CalcResult-arrow {
  position: absolute;
  top: 50%;
  right: -1rem;
}

.Partners {
  margin-top: 4rem;
  margin-bottom: 3rem;
}

.Partners .slick-track {
  display: flex;
  align-items: center;
}

.Partners-logo {
  margin: 0 auto;
}

.Cta {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 1rem;
  width: 18rem;
  padding: 2.5rem 2.5rem 2.5rem 3.75rem;
  border-radius: 1rem 0 0 1rem;
  box-shadow: 1rem 1rem 2rem rgba(0, 0, 0, 0.16);
  font-size: 0.875rem;
}

.Cta-title {
  margin-top: 0;
}

.Cta-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;
  font-size: 1.75rem;
  line-height: 1;
}

.Testimonials .Carousel-prev,
.Testimonials .Carousel-next {
  margin-top: -2.5rem;
}

.Testimonial {
  max-width: 60em;
  margin: 0 auto;
  font-size: 0.875rem;
}

.Testimonial-footer {
  margin-top: 2rem;
  font-size: 1rem;
}

.Testimonial-cite {
  font-style: normal;
}

.Asset {
  font-size: 0.875rem;
}

.Asset b {
  display: inline-block;
}

.Asset img {
  margin-bottom: 25px;
}

.Asset ul {
  list-style: none;
  padding: 0;
}

.Asset-title {
  font-weight: 500;
  font-size: 2em;
}

.Footer {
  padding: 1.5rem 0;
  background: #13131e;
  color: #fff;
}

.Footer-copy {
  font-size: 0.875rem;
}

.Footer-menu {
  margin: 1rem 0;
  font-weight: 700;
}

.Footer-menu > li > a {
  color: #fff;
}

@media (min-width: 576px) {
  .Footer-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
  }
  .Footer-menu > li {
    position: relative;
  }
  .Footer-menu > li:not(:first-child):before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    height: 1rem;
    margin-top: -.5rem;
    border-left: 1px solid;
    vertical-align: middle;
  }
  .Footer-menu > li > a {
    padding: .5rem 1rem;
  }
}

@media (min-width: 768px) {
  .Footer-menu {
    margin: 0;
  }
}

@keyframes scrolling {
  0% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px);
  }
  100% {
    transform: translateY(-2px);
  }
}

/*
============================================
			MOUSE SCROLL ICON
============================================
*/
.how-refis-works-section {
  margin-top: -45px;
}

.scroll-down {
  z-index: 101;
  cursor: pointer;
  position: absolute;
  right: 30px;
  top: 35px;
  text-align: center;
}

.scroll-down img {
  width: 12px;
  height: auto;
  animation-name: scrolling;
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-out;
}

.MouseWrapper {
  position: relative;
  width: 100%;
  text-align: center;
}

.mouse {
  transform: scale(0.65);
  width: 33px;
  height: 60px;
  border-radius: 60px;
  border: solid 3px #082848;
}

.mouse .scroller {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 0;
  top: 15px;
}

.mouse .scroller .scroll-wheel {
  width: 5px;
  height: 9px;
  border-radius: 3px;
  background-color: #082848;
  display: block;
  margin: 0 auto;
}

.Header {
  position: absolute;
  z-index: 1030;
  top: 32px;
  left: 0;
  width: 100%;
  color: #fff;
  background: #082948;
  box-shadow: 0 0 1rem rgba(7, 16, 52, 0.1);
  transition: box-shadow 250ms ease-in-out;
}

.Header--transparent {
  background: none;
  box-shadow: none;
  color: #fff;
}

.Header--transparent.is-fixed {
  position: fixed;
  top: 0;
  background: linear-gradient(45deg, #040a18, #082948);
  box-shadow: 0 0 3rem rgba(7, 16, 52, 0.1);
}

.Header-logo {
  display: block;
  padding: 1.0625rem 1rem;
  margin-left: -1rem;
}

.Header-navInner {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
}

.Header-menu {
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0;
}

.Header-menu > li > a {
  display: flex;
  align-items: center;
  padding: .5rem 1rem;
}

.Header-menu > li > a:not(.btn) {
  color: inherit;
}

.Header-menu > li.is-active {
  font-weight: 700;
}

.Header-lang {
  position: relative;
}

.Header-lang:before {
  content: '';
  position: absolute;
  top: 50%;
  left: 0;
  display: block;
  height: 1rem;
  margin-top: -.5rem;
  border-left: 1px solid;
  vertical-align: middle;
}

.Header-demo {
  margin-left: .5rem;
}

.Header-navToggle {
  height: 3.75rem;
  padding: 1rem 1rem;
  margin-right: -1rem;
  font-family: "Benton Sans", "Libre Franklin", sans-serif;
  line-height: 1;
}

.Header-navToggle.is-active {
  background: #082948;
}

.Header-navToggleIcon {
  display: block;
  color: #fff;
  vertical-align: -.4em;
  margin-right: .25rem;
}

@media (min-width: 992px) {
  .Header-nav {
    display: block !important;
  }
}

@media (max-width: 991.98px) {
  body.is-navOpen {
    overflow: hidden;
  }
  .Header {
    transform: none !important;
  }
  .Header-nav {
    display: none;
    position: fixed;
    z-index: -1;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #082948;
    text-align: center;
  }
  body.is-navOpen .Header-nav {
    overflow-x: hidden;
    overflow-y: auto;
  }
  .Header-navInner {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    height: auto;
    transform: translate(0, -30px);
    transition: transform 250ms ease-out, opacity 250ms ease-out;
  }
  .Header-nav.is-shown .Header-navInner {
    transform: translate(0, 0);
  }
  .Header-menu {
    flex: 0 0 auto;
    display: block;
    height: auto;
    padding: 1rem 0;
    text-align: center;
  }
  .Header-menu > li:not(:last-child) {
    margin-bottom: .5rem;
  }
  .Header-menu > li > a {
    display: block;
  }
  .Header-lang:before {
    position: static;
    width: 1rem;
    height: auto;
    border: none;
    border-top: 1px solid;
    margin: 0 auto .5rem;
  }
}

.bg-gradient-dark-blue {
  color: #FFF;
  background: linear-gradient(45deg, #040a18, #082948);
}

.color-primary {
  color: #f5bd52;
}

.container,
.container-fluid {
  padding-right: 1rem;
  padding-left: 1rem;
}

.row-lg {
  margin-right: -1rem;
  margin-left: -1rem;
}

.row-lg > [class*="col-"],
.row-lg > .col,
.row-lg > .slick-list [class*="col-"],
.row-lg > .slick-list .col {
  padding-right: 1rem;
  padding-left: 1rem;
}

.row-sm {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.row-sm > [class*="col-"],
.row-sm > .col,
.row-sm > .slick-list [class*="col-"],
.row-sm > .slick-list .col {
  padding-right: .5rem;
  padding-left: .5rem;
}

.row-xs {
  margin-right: -0.3125rem;
  margin-left: -0.3125rem;
}

.row-xs > [class*="col-"],
.row-xs > .col,
.row-xs > .slick-list [class*="col-"],
.row-xs > .slick-list .col {
  padding-right: 0.3125rem;
  padding-left: 0.3125rem;
}

.row-no-gutter {
  margin-left: 0;
  margin-right: 0;
}

.row-no-gutter > [class*="col-"],
.row-no-gutter > .col,
.row-no-gutter > .slick-list [class*="col-"],
.row-no-gutter > .slick-list .col {
  padding-right: 0;
  padding-left: 0;
}

.col-auto {
  max-width: 100%;
}

@media (min-width: 768px) {
  .col-md-fifth {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 992px) {
  .col-lg-fifth {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media (min-width: 1200px) {
  .col-xl-fifth {
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.modal-dialog {
  position: relative;
}

.modal-header {
  display: block;
  padding-bottom: 2rem;
  background: #f5bd52;
  color: #fff;
  border-color: transparent;
}

.modal-title {
  padding-right: 1.5rem;
}

.modal-title:not(:last-child) {
  margin-bottom: 2rem;
}

.modal-close {
  position: absolute;
  z-index: 10;
  top: 0;
  right: 0;
  width: 3.5rem;
  height: 3.5rem;
  padding: .5rem;
  color: #fff;
  font-size: 1.75rem;
  line-height: 1;
}

.modal-close:hover {
  opacity: .75;
  transform: scale(1.285714286);
}

.modal-body {
  border-color: transparent;
}

.modal-submit {
  margin-top: 3rem;
}

@media (max-width: 767.98px) {
  .modal-header {
    padding: 1.5rem;
  }
  .modal-body {
    padding: 1.5rem;
  }
}

.form-group-lg {
  margin-bottom: 1.75rem;
}

.form-control-white {
  border-color: #fff;
}

.form-control-white, .form-control-white:focus {
  background: none;
  color: #fff;
}

.form-control-white:focus {
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(255, 255, 255, 0.25);
}

.form-control-white::placeholder {
  color: rgba(255, 255, 255, 0.75);
}

textarea.form-control {
  resize: none;
}

.custom-control + .custom-control {
  margin-top: .5rem;
}

.custom-control-label::before {
  box-shadow: 0 0.125rem 4rem rgba(7, 16, 52, 0.1);
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: transparent;
}

@keyframes dropdownChevron {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(0.125rem);
  }
}

.dropdown-toggle::after {
  content: none;
}

.dropdown-chevron {
  font-size: 0.75rem;
  vertical-align: -0.3ex;
}

.table th {
  color: #13131e;
}

.table thead th {
  padding-bottom: 1rem;
}

.table .thead-sm th, .table .thead-sm td {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.table:last-child {
  margin-bottom: 0;
}

.table-no-header tr:first-child td,
.table-no-header tr:first-child th {
  border-top: none;
}

.table-vertically-centered th, .table-vertically-centered td {
  vertical-align: middle;
}

.page-link {
  min-width: 2.25rem;
  text-align: center;
  transition: background-color 250ms;
}

.breadcrumb a {
  color: inherit;
}

.breadcrumb-item.active {
  font-weight: 700;
}

.media-body {
  min-width: 0;
}

.tooltip-inner,
.tooltip .arrow {
  opacity: 0;
  transition: opacity 500ms cubic-bezier(0.23, 1, 0.32, 1), transform 500ms cubic-bezier(0.23, 1, 0.32, 1);
  transform: translate3d(0, -20px, 0);
}

.tooltip.show .tooltip-inner {
  opacity: 1;
  transform: translate3d(0, 0, 0);
}

.tooltip.show .arrow {
  opacity: 1;
  transform: translate3d(0, -1px, 0);
}

.tooltip-inner {
  padding: 1.5rem;
  box-shadow: 3px 3px 30px rgba(0, 0, 0, 0.09);
}

.text-tertiary {
  color: #8095ff !important;
}

.text-muted-light {
  color: #757c84 !important;
}

.text-normal {
  text-transform: none !important;
  letter-spacing: 0 !important;
}

.align-items-center {
  align-items: center;
}

.justify-content-center {
  justify-content: center;
}

.bg-primary {
  background-color: #f5bd52 !important;
}

.bg-secondary {
  background-color: #082948 !important;
}

.bg-light {
  background-color: #e8e8e8 !important;
}

.bg-white {
  background-color: #fff !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pr-0 {
  padding-right: 0 !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.p-0 {
  padding: 0 !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.border-top {
  border-top: 1px solid #d1d9dd;
}

.border-bottom {
  border-bottom: 1px solid #d1d9dd;
}

.overflow-hidden {
  overflow: hidden !important;
}

.invisible {
  visibility: hidden !important;
}
