*, *:before, *:after {
	box-sizing: border-box;
}
html {
  height: 100%;
  background-color: $white;
}
body {
  position: relative;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  overflow-x: hidden;
  background-color: $white;
}

.MainWrapper {
  position: relative;
}
// .Page {
//   overflow: hidden;
// }
// .Main {
//   overflow: hidden;
// }

img {
	max-width: 100%;
}

label,
.label {
  margin-bottom: .5rem;
  font-weight: 400;
  font-size: $font-size-base;
}

.color-white{
    color: #FFF;
    &:hover{
        color: #FFF;
    }
}

.margin{
    &-top{
        &--10{
            margin-top: 10px;
        }
        &--20{
            margin-top: 20px;
        }
        &--30{
            margin-top: 30px;
        }
        &--50{
            margin-top: 50px;
        }
    }
    &-bottom{
        &--5{
            margin-bottom: 5px;
        }
        &--10{
            margin-bottom: 10px;
        }
        &--20{
            margin-bottom: 20px;
        }
        &--30{
            margin-bottom: 30px;
        }
        &--50{
            margin-bottom: 50px;
        }
    }
    &-vertical{
        &--30{
            margin: 30px 0;
        }
        &--50{
            margin: 50px 0;
        }
    }
}

.padding{
    &-top{
        &--10{
            padding-top: 10px;
        }
        &--20{
            padding-top: 20px;
        }
        &--30{
            padding-top: 30px;
        }
        &--50{
            padding-top: 50px;
        }
    }
    &-vertical{
        &--20{
            padding-top: 20px;
            padding-bottom: 20px;
        }
        &--30{
            padding-top: 30px;
            padding-bottom: 30px;
        }
        &--50{
            padding-top: 50px;
            padding-bottom: 50px;
        }
        &--80{
            padding-top: 80px;
            padding-bottom: 80px;
        }
        &--100{
            padding-top: 100px !important;
            padding-bottom: 100px !important;
        }
        &--120{
            padding-top: 120px !important;
            padding-bottom: 120px !important;
        }
    }
}


/**
 * lists
 */
ul:not(.list-unstyled) {
	padding-left: 1.3125rem;
	// list-style-image:  url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='%23ee1900' cx='3' cy='5' r='3'/%3e%3c/svg%3e");

  list-style-image:  str-replace(url("data:image/svg+xml;charset=UTF-8,%3csvg width='10' height='10' xmlns='http://www.w3.org/2000/svg'%3e%3ccircle fill='#{$primary}' cx='3' cy='5' r='3'/%3e%3c/svg%3e"), "#", "%23");

	// > li {
	// 	padding-left: 5px;
 //  }
 //  > li + li {
 //    margin-top: .5rem;
 //  }

	// 	> ul {
	// 		list-style-image: url('data:image/svg+xml;utf8,
	// 		<svg width="10px" height="10px" viewBox="0 0 10 10" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
	// 	        <g id="Group">
	// 	            <circle fill="%23EE3582" cx="3" cy="5" r="3"></circle>
	// 	            <circle fill="%23FFFFFF" cx="3" cy="5" r="1"></circle>
	// 	        </g>
	// 		</svg>');
	// 	}
	// }
}
ol:not(.list-unstyled) {
  padding-left: 1.3125rem;
}
// .list-unstyled,
// .list-inline {
// 	> li {
// 		padding-left: 0;
// 		margin-top: 0;
// 	}
// }
// li + li {
// 	margin-top: .5rem;
// }

