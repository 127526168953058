
// ==========================================================================
// Logo
// https://csswizardry.com/2013/01/your-logo-is-still-an-image-and-so-is-mine/
// ==========================================================================
.Logo {
  display: inline-block;
  // max-width: 10.75rem;
}
.Logo-image {
  display: block;
  width: 7rem;
  height: 1.625rem;
  background: url("../img/logo-refis-flow.svg") 50% 50%/contain no-repeat;
  transition: background-image 250ms ease-in-out;
}



// ==========================================================================
// Hamburger
// ==========================================================================
.Hamburger {
  display: inline-block;
  width: 1.5rem;
  transition: transform 250ms;
}
.Hamburger-bar {
  height: 0.1875rem;
  border-radius: .5rem;
  background-color: currentColor;

  transition: transform 250ms, opacity 250ms;

  & + & {
    margin-top: .25rem;
  }
}

.is-active {
  .Hamburger {
    transform: rotate(90deg);
  }
  .Hamburger-bar:nth-child(1) {
    transform: translateY(.25rem +  0.1875rem) rotate(45deg);
  }
  .Hamburger-bar:nth-child(2) {
    transform: scaleX(0);
    opacity: 0;
  }
  .Hamburger-bar:nth-child(3) {
    transform: translateY(-.25rem - 0.1875rem) rotate(-45deg);
  }
}

// ==========================================================================
// Arrow
// ==========================================================================
.Arrow {
  position: relative;
  display: inline-block;
  width: 2rem;
  // transition: width 500ms $ease-out-quint;;
  transition: $transition-base;

  &::before,
  &::after {
    content: '';
    position: absolute;
    top: 50%;
    right: 1px;

    display: block;
    width: 0.375rem;
    border-bottom: 2px solid;
    margin-top: -1px;
    transform-origin: center right;

  }
  &::before {
    transform: rotate(45deg);
  }
  &::after {
    transform: rotate(-45deg);
  }
}
.Arrow-bar {
  display: block;
  border-bottom: 2px solid;
  border-radius: 0 .5rem .5rem 0;

}

.Arrow--left {
  transform: rotate(180deg);
}
.Arrow--down {
  transform: rotate(90deg);
}
.Arrow--up {
  transform: rotate(-90deg);
}
// ==========================================================================
// Demo
// ==========================================================================
.ContactBox {
  width: 27rem;
  max-width: 100%;
  padding: 3rem 2.5rem 2.5rem;
  margin: 0 auto;
  box-shadow: 1rem 1rem 2rem rgba(black, .16);
}
.ContactBox-title {
  margin-bottom: 2.5rem;
}
.ContactBox-submit {
  margin-top: 2rem;
}


@include media-breakpoint-down(sm) {
  .ContactBox {
    padding: 1rem;
  }
}
// ==========================================================================
// Video
// ==========================================================================
.Video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
  background: $gray-400;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

// ==========================================================================
// Carousel
// ==========================================================================
.Carousel {
  padding-left: 3rem;
  padding-right: 3rem;
}
.Carousel-prev,
.Carousel-next {
  color: $text-color;
  width: 3rem;
  height: auto;
  padding: 2rem 0;
  font-size: 1rem;
  line-height: 1;
  opacity: .25;
  transition: $transition-base;

  &::before {
    content: none;
    display: none;
  }

  &:focus {
    color: $text-color;
  }

  &:hover {
    color: $text-color;
    opacity: 1;

    .Arrow-bar {
      transform: scaleX(1.3);
    }
  }

  .Arrow {
    display: block;
    margin: 0 auto;
  }
  .Arrow-bar {
    transition: $transition-base;
    transform-origin: right center;
  }
}

.Carousel-prev {
  left: 0;
}
.Carousel-next {
  right: 0;
}

@include media-breakpoint-up(md) {
  .Carousel {
    padding-left: 5rem;
    padding-right: 5rem;
  }
  .Carousel-prev,
  .Carousel-next {
    color: $text-color;
    width: 4rem;
  }
}

// ==========================================================================
// Range
// ==========================================================================
.Range {
  > .row {
    align-items: center;
  }
}

.Range-control {
  display: block;
}
.Range-value {
  padding: 0;
  width: 3rem;
  color: $primary;

  &:disabled,
  &[readonly] {
    background: none;
  }
}

// ==========================================================================
// CurrencyInput
// ==========================================================================
.CurrencyInput {
  position: relative;
}
.CurrencyInput-input {
  padding-right: 3.5rem;
}
.CurrencyInput-currency {
  position: absolute;
  top: 50%;
  right: 1rem;
  // line-height: 1;
  margin-top: 1px;
  color: $primary;
  transform: translateY(-50%);
}

// ==========================================================================
// Hero
// ==========================================================================
%bg,
%overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
%bg {
  z-index: -10;
  background-position: 50% 50%;
  background-size: cover;
  background-repeat: no-repeat;
  transition: opacity 250ms ease-in-out;
}
%overlay {
  z-index: 0;
}
.Hero {
  position: relative;
  z-index: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 25rem;
  padding: (6rem + $header-height) 0 6rem;
  background: $secondary;
  color: $white;

  > .container {
    position: relative;
  }
}

.Hero-bg {
  @extend %bg;
}

.Hero-overlay {
  @extend %overlay;
  background: linear-gradient(45deg, $secondary-dark, $secondary);
  opacity: .85;
}



.Hero-inner {
  padding: 4rem 0;
}

.Hero-title {
  color: inherit;
  margin-bottom: 2.25rem;
}

.HeroBenefits-tiles {
  position: relative;
  top: -4rem;
}

.HeroContact {
  position: relative;
  z-index: 1;
  padding: 4rem 0;
}
.HeroContact-image {
  width: 32.5rem;
  margin-bottom: -4rem;
}
.HeroContact-box {
  margin-top: -9rem;
  margin-bottom: -7rem;
}

@include media-breakpoint-down(md) {
  .HeroContact-image {
    margin-bottom: 0;
  }
  .HeroContact-box {
    margin-top: 0;
  }
}

//
// Hero homepage
// ==========================================================================
.Hero--homepage {
  min-height: 34rem;

  .Hero-overlay {
    background: $secondary;
    opacity: .5;
  }
}

@media (max-height: 767.98px) {
  .Hero--homepage {
    min-height: 26rem;
  }
}

//
// Hero demo
// ==========================================================================
.Hero--demo {
  padding-bottom: 0;
}

@include media-breakpoint-down(md) {
  .Hero--demo {
    .Hero-title {
      margin-bottom: 4rem;
    }
  }
}

// ==========================================================================
// Hero Features
// ==========================================================================
.HeroFeatures {
  padding: 3rem 0;
}
.HeroFeatures-image {
  width: 37.5rem;
  margin-top: -10rem;
  margin-bottom: -4rem;
}

@include media-breakpoint-down(sm) {
  .HeroFeatures-image {
    margin-bottom: -2rem;
  }
}


// ==========================================================================
// Section
// ==========================================================================
.Section {
  position: relative;
  // z-index: 0;
  padding: 5rem 0;

  > .container {
    position: relative;
  }
  &.Asset-section{
    padding: 6rem 0;
  }
}

@media (max-height: 767.98px) {
  .Section {
    padding: 4rem 0;
  }
}

.Section-bg {
  @extend %bg;
}
.Section-overlay {
  @extend %overlay;
  background: rgba($white, .9);
}

.Section-header {
  margin-bottom: 2.5rem;
}
.Section-title {
  font-weight: 300;
  color: $secondary;

  &:after {
    content: '';
    display: block;
    width: 4rem;
    margin: 1.5rem auto 0;
    border-bottom: 2px solid;
  }
}
.Section-text:not(:last-child) {
  margin-bottom: 2.5rem;
}

.Section--sm {
  padding: 4rem 0;
}

.Section--dark {
  background-image: linear-gradient(45deg, $secondary-dark, $secondary);
  background-color: $secondary;

}
.Section--light {
  background: #f1f1f1;
}

// ==========================================================================
// CheckList
// ==========================================================================
.CheckList {
  padding-left: 2rem;
  margin-bottom: 0;

  > li {
    position: relative;

    &:not(:last-child) {
      margin-bottom: .5rem;
    }

    &:before {
      content: $icon-check;
      position: absolute;
      left: -2rem;
      top: 0;
      font-size: 1.25rem;


      font-family: '#{$icomoon-font-family}' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }
}

// ==========================================================================
// Map
// ==========================================================================
.Map {
  min-height: 22.1875rem;

  // background: url('../img/contact-map.jpg') 50% 50% / cover no-repeat;
  background-color: $light;

  &::before {
    content: '';
    display: block;
    padding-bottom: 27.34375%;
  }
}

// @include retina {
//   .Map {
//     background-image: url('../img/contact-map@2x.jpg');
//   }
// }

// ==========================================================================
// Pricing
// ==========================================================================

.Pricing-item {
  position: relative;
}
.Pricing-body {
  padding: 2rem 1rem;
  border: 1px solid $secondary;
  margin-bottom: 1rem;
}
.Pricing-badge {
  position: absolute;
  top: 0;
  padding: 0.375rem 2rem;

  transform: translate(-50%, -50%);
  border-radius: 999px;
  font-size: 0.8125rem;

  &:after {
    content: '\02605';
    position: absolute;
    margin-left: .5rem;
  }
}
.Pricing-title {
  margin-top: 0;
  font-size: 1.5625rem;
}
.Pricing-features {
  margin-bottom: 0;
  &:before {
    content:'';
    display: block;
    width: 8.25rem;
    margin: 1rem auto;
    border-bottom: 1px solid $secondary;
  }

  > li {
    padding: .5rem 0;
  }
}

.Pricing-label {
  font-size: 0.85rem;
}




@include media-breakpoint-up(lg) {
  .Pricing {
    position: relative;
    padding-left: 16rem;
  }
  .Pricing-col {
    position: static;
  }

  .Pricing-col:first-child {
    .Pricing-label {
      position: absolute;
      left: -16rem;
    }
  }
  .Pricing-col:not(:first-child) {
    .Pricing-label {
      @include sr-only();
    }
  }
}
@include media-breakpoint-down(md) {
  .Pricing-col {
    margin-bottom: 2rem;
  }
  .Pricing-features {
    > li {
      display: flex;
    }
  }
  .Pricing-label {
    flex: 1 1 auto;
    align-items: center;
    text-align: left;
    padding-right: 1rem;
  }
}


// ==========================================================================
// Steps
// ==========================================================================

.Steps {
  margin-bottom: 2.5rem;
}

.Step {
  height: 100%;
  position: relative;
  background: $white;
  box-shadow: 0 0 1rem rgba(black, .05);
  transition: all .3s ease;
}
.Step-arrow {
  position: absolute;
  z-index: 10;
  top: 3.75rem;
  right: -.6rem;
}
.Step-inner {
  position: relative;
  z-index: 0;
  height: 100%;
  padding: 3rem 2rem 2.5rem;
  overflow: hidden;
}

.Step-icon {
  position: absolute;
  z-index: -1;
  bottom: -.075em;
  right: 1.5rem;

  font-size: 9rem;
  line-height: 1;
  color: $white;
  opacity: .35;
}

.Step-number {
  color: $primary;
  margin-top: 0;
}

.Step--secondary {
  border-bottom: 4px solid $primary;

  .Step-arrow {
    top: 50%;
    right: 4.5rem;
    transform: translateY(-50%);
  }
  .Step-inner {
    padding: 1.5rem 7.75rem;
  }
}

.Step--highlight {
  //background-image: linear-gradient(to right, $primary, $primary-light);
  //background-color: $primary;
  .Step-number {
    color: $white;
  }
}

.Highlighted-bg{
  display: none;
  background-image: linear-gradient(to right, $primary, $primary-light);
  background-color: $primary;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0; left: 0;
  z-index: -1;
}

.Step-01{
  .Highlighted-bg{
    display: block;
  }
}

.StepsCTA {
  margin: 2.5rem 0 -6rem;
}
@include media-breakpoint-down(sm) {
  .Steps-item:not(:last-child) {
    margin-bottom: 1rem;
  }

  .Step-arrow {
    top: auto;
    right: 2rem;
    bottom: -.6rem;

    transform-origin: center right;
    transform: rotate(90deg);
  }

  .Step--secondary {
    border: none;
    border-left:  0.25rem solid $primary;

    text-align: left;
    .Step-inner {
      padding: 1.5rem 4rem 1.5rem 1.75rem;
    }
    .Step-arrow {
      right: 2rem;
      bottom: auto;
      margin-top: 1rem;

      transform: rotate(90deg);
    }
  }
}


// ==========================================================================
// Target
// ==========================================================================
.Targets {
  margin: 0 -1px;
}
.Targets-item {
  padding: 0 1px;
  margin-bottom: 2px;
}
.Target {
  position: relative;
  z-index: 0;
  padding: 2.5rem 2rem 2.5rem 9.375rem;
  border: 1px solid $gray-200;
  color: $dark;
  font-size: 0.875rem;
  transition: background 250ms ease-in-out;


  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    display: block;
    width: 100%;
    height: 100%;
    background: linear-gradient(to right, $gray-100, $white);

    opacity: 0;
    transition: opacity 250ms ease-in-out;
  }

  &:hover,
  &:focus-within {
    &:before {
      opacity: 1;
    }

    .Target-arrow {
      width: 3rem;
    }
  }
}
.Target-title {
  a {
    color: inherit;
  }
}
.Target-arrow {
  position: absolute;
  margin-top: .5em;
  margin-left: -4rem;
}
.Target-text {
  p {
    &:last-child {
      margin-bottom: 0;
    }
  }
}
.Target-more {
  color: $primary;
  font-weight: 700;
  text-decoration: underline;
}

@include media-breakpoint-down(md) {
  .Target {
    padding: 2rem 1rem 2rem 5.5rem;
  }
}

// ==========================================================================
// Badge
// ==========================================================================
.Badges {
  font-size: 0;
  margin-bottom: -.375rem;
}
.Badges-item {
  display: inline-block;
  margin-right: 0.375rem;
  margin-bottom: 0.375rem;
}
.Badge {
  display: inline-block;
  padding: 0.4375em 0;
  min-width: 2em;
  border-radius: 0.1875em;
  border: 1px solid $gray-200;

  color: $primary;
  font-weight: 700;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  text-align: center;

  transition: all 250ms;
}

// .Badge--lg {
//   padding: .5rem 1rem;

//   font-size: 1rem;
//   text-transform: none;
//   letter-spacing: 0;
// }

.Badge--sm {
  font-size: 0.875rem;
  letter-spacing: -.03em;
}

.Badge--pill {
  border-radius: 999px;
}

.Badge--primary {
  border-color: transparent;
  background-color: $primary;
  color: $white;
}
a.Badge--primary:hover,
a.Badge--primary:focus {
  color: $white;
  background-color: darken($primary, 7.5%)
}

.Badge--transparentWhite {
  padding: .5rem 1rem;
  background: rgba($white, .2);
  border-color: transparent;
  border-radius: 0.375rem;

  font-weight: 700;
  font-size: 0.875rem;
  color: $white;
  text-transform: none;

  &:hover,
  &:focus {
    color: $white;
  }
}
// .Badge--secondary {
//   padding: .5rem 1rem;
//   background: rgba($primary-dark, .2);
//   border-color: transparent;
//   border-radius: 0.375rem;

//   font-weight: 700;
//   font-size: 0.875rem;
//   color: $primary-dark;
//   text-transform: none;
// }

// .Badge--success {
//   color: $text-color;
//   background-color: $highlight-green;
// }
// a.Badge--success:hover,
// a.Badge--success:focus {
//   color: $text-color;
//   background-color: mix(black, $highlight-green, 7.5%)
// }

// .Badge--warning {
//   color: $text-color;
//   background-color: $highlight-yellow;
// }
// a.Badge--warning:hover,
// a.Badge--warning:focus {
//   color: $text-color;
//   background-color: mix(black, $highlight-yellow, 7.5%)
// }


// ==========================================================================
// Contact
// ==========================================================================
.ContactHeading {
  padding-left: 3rem;
  margin-bottom: 1.5rem;
}

.Contact {
  display: flex;

  & + & {
    margin-top: 1.5rem;
  }
}
.Contact-icon {
  width: 1.5rem;
  margin-right: 1.5rem;
  font-size: 1.3125em;
}
.Contact-body {
  flex: 1 1 auto;
}
address.Contact-body {
  line-height: 2;
  margin-top: -0.3em;
  margin-bottom: 0;
}

@include media-breakpoint-down(sm) {
  .ContactSection {
    margin-bottom: 3rem;
  }
}



// ==========================================================================
// Article
// ==========================================================================
$a-font-size: 1.125rem;
$a-line-height: 1.75;
$a-computed-line-height: $a-font-size * $a-line-height;
$a-visual-offset: .5rem; // compensate text white space
$a-margin: 2rem;

.Measure {
  max-width: 58.5rem;
  margin: 0 auto 0;
}
.Article {
  position: relative;

  p,
  ul,
  ol,
  .table-responsive,
  .table,
  table,
  h1, .h1,
  h2, .h2,
  h3, .h3,
  h4, .h4,
  h5, .h5,
  h6, .h6 {
    margin-bottom: $a-margin;
  }

  .table-responsive {
    .table,
    table {
      margin-bottom: 0;
    }
  }

  .lead {
    color: $dark;
    font-weight: 700;
  }

  img,
  figure,
  .row,
  .row [class*="col-"] {
    page-break-inside:avoid;
    page-break-after:avoid;
  }

  img {
    display: block;
    margin-bottom: $a-margin;
  }


  figure {
    position: relative;
    margin-bottom: $a-margin;

    img {
      margin: 0 auto;
    }
  }
  figcaption {
    padding: 1.5rem;
    background: $dark;
    color: $white;
    font-weight: 500;
    font-size: 0.875rem;
  }
  .figcaption-source {
    float: right;
    font-weight: 400;
    margin-left: 1.5rem;
    margin-bottom: 1rem;
  }
}

.ArticleContent {
  margin: 3rem 0;

  &:first-child {
    margin-top: 0;
  }
}
.ArticleNetworks {
  position: absolute;
  top: 0;
  right: -5.25rem;
}

@include media-breakpoint-down(lg) {
  .ArticleNetworks {
    position: static;
    text-align: center;

    > li {
      display: inline-block;

      &:not(:last-child) {
        margin-right: .5rem;
      }
    }
  }
  .Article {
    .lead {
      font-size: 1.3125rem;
    }
  }
}

@include media-breakpoint-down(xs) {
  .Article {
    figure,
    img {
      margin-left: -1rem;
      margin-right: -1rem;
    }
    figure img {
      margin-left: auto;
      margin-right: auto;
    }

    figcaption {
      display: flex;
      flex-wrap: wrap;
      text-align: center;
    }
    .figcaption-content {
      flex: 1 0 100%;
    }
    .figcaption-source {
      order: 99;
      flex: 1 0 100%;
      margin: 1.5rem -1.5rem -1.5rem;
      padding: 1rem 1.5rem 0;
      background: $light;
      color: $text-muted;
    }
  }
}
// ==========================================================================
// Tiles
// ==========================================================================
.Tiles {
  justify-content: center;
  // margin: 7rem -1.5625rem 0;
}
.Tiles-item {
  // padding: 0 1.5625rem;
  flex-direction: column;
  margin-bottom: 1.5rem;

  > .Tile {
    height: 100%;
  }
}


.Tile {
  display: flex;
  flex-direction: column;
  padding: 2.5rem 2rem 2rem;
  background: $white;
  box-shadow: 3px 3px 30px rgba(black, .09);

  color: inherit;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.3;
  text-align: center;

  transition: background 250ms ease-in-out;
  a{
    color: $secondary;
    &:hover{
      text-decoration: none;
    }
  }
}

a.Tile:hover,
a.Tile:focus,
.Tile[data-toggle="tooltip"]:hover,
.Tile[data-toggle="tooltip"]:focus {
  background: $light;
  color: inherit;
  text-decoration: none;

  .Tile-title {
    text-decoration: underline;
    &.no-underline{
      text-decoration: none;
    }
  }
}
.Tile-icon {
  display: block;
  font-size: 3.25rem;
  margin: -.25rem auto .75rem;
}
.Tile-title {
  display: block;
  margin: auto 0;

  &:last-child {
    margin-bottom: auto;
  }
}

.Tile--border {
  background: none;
  border: 1px solid;
  box-shadow: none;
}
a.Tile--border:hover,
a.Tile--border:focus,
.Tile--border[data-toggle="tooltip"]:hover,
.Tile--border[data-toggle="tooltip"]:focus {
  background: rgba($white, .1);
}

.Tile--subtle {
  box-shadow: none;
  background: none;
  border: 1px solid transparent;
  transition: border-color 250ms ease-in-out;
}
a.Tile--subtle:hover,
a.Tile--subtle:focus,
.Tile--subtle[data-toggle="tooltip"]:hover,
.Tile--subtle[data-toggle="tooltip"]:focus {
  border-color: $secondary;
  background: none;
}


.Tile--square {
  position: relative;

  &::before {
    content: '';
    display: block;
    padding-bottom: 100%;
  }

  .Tile-inner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
  }
  .Tile-logo {
    flex: 0 1 auto;
  }
}

// ==========================================================================
// Usage
// ==========================================================================
.Usage {
  overflow: hidden;
}
.Usage-mapSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-height: 42rem;
}
.Usage-title {
  font-size: 1.875rem;
}


.Usage-map {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;

  background-size: contain;
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

@include media-breakpoint-up(lg) {
  .Usage-map {
    left: 50%;
    width: 50%;
  }
}

@include media-breakpoint-up(xl) {
  .Usage-map {
    bottom: 2rem;
    background-size: cover;
    background-position: 100% 100%;
  }
}

// ==========================================================================
// Stats
// ==========================================================================

.Stats {
  margin-bottom: -2rem;
}
.Stats-item {
  margin-bottom: 2rem;
}
.Stat-value {
  font-size: 1.875rem;
}

// ==========================================================================
// Results
// ==========================================================================
.Results {
  margin-bottom: -2rem;
}
.Results-item {
  margin-bottom: 2rem;
}
.Results-tile {
  min-height: 11.25rem;
  margin-bottom: 1rem;
}
.Results-value {
  display: block;
  margin-bottom: .25rem;
  font-size: 1.875rem;
}
.Results-label {
  font-size: 0.875rem;
}


// ==========================================================================
// Circle
// ==========================================================================
.Circle-wrap {
  position: relative;
  display: inline-block;
}
.Circle-text {
  position: absolute;
  top: 50%;
  left: 0px;
  text-align: center;
  width: 100%;
  font-size: 1.125rem;
  transform: translateY(-50%);
}
.Circle-maxValueStroke {
  stroke-width: 2px;
  stroke: $gray-100;
}
.Circle-valueStroke {
  stroke-width: 4px;
  stroke: $primary;
}

// ==========================================================================
// CalcResult
// ==========================================================================
.CalcResult {
  position: relative;
  padding-left: 2.5rem;
}
.CalcResult-col {
  position: static;
}
.CalcResult-number {
  position: absolute;
  top: -.125rem;
  left: 0;
  display: block;
  font-size: 1.5rem;
  line-height: 1;
}
.CalcResult-title {
  margin-top: 0;
}
.CalcResult-text {
  font-size: 0.875rem;
  text-align: justify;
}
.CalcResult-result {
  position: relative;
  display: block;
  padding-right: 1rem;
  font-size: 1.125rem;
}
.CalcResult-resultLarge {
  font-size: 1.5rem;
}
.CalcResult-resultSmall {
  font-size: 0.8125rem;
  line-height: 1.2;
}
.CalcResult-arrow {
  position: absolute;
  top: 50%;
  right: -1rem;
}

// ==========================================================================
// Partners
// ==========================================================================
.Partners {
  margin-top: 4rem;
  margin-bottom: 3rem;

  .slick-track {
    display: flex;
    align-items: center;
  }
}
.Partners-logo {
  margin: 0 auto;
}

// ==========================================================================
// Cta
// ==========================================================================
.Cta {
  position: fixed;
  z-index: 100;
  right: 0;
  bottom: 1rem;

  // display: none;
  width: 18rem;
  padding: 2.5rem 2.5rem 2.5rem 3.75rem;
  border-radius: 1rem 0 0 1rem;
  box-shadow: 1rem 1rem 2rem rgba(black, .16);
  font-size: 0.875rem;
}
.Cta-title {
  margin-top: 0;
}
.Cta-close {
  position: absolute;
  top: 0;
  right: 0;
  padding: 1rem;

  font-size: 1.75rem;
  line-height: 1;

}

// ==========================================================================
// Testimonials
// ==========================================================================
.Testimonials {
  .Carousel-prev,
  .Carousel-next {
    margin-top: -2.5rem;
  }
}
.Testimonial {
  max-width: 60em;
  margin: 0 auto;
  font-size: 0.875rem;
}
.Testimonial-footer {
  margin-top: 2rem;
  font-size: 1rem;
}
.Testimonial-cite {
  font-style: normal;
}
// ==========================================================================
// Assets
// ==========================================================================
.Asset{
  font-size: 0.875rem;
  b{
    display: inline-block;
  }
  img{
    margin-bottom: 25px;
  }
  ul{
    list-style: none;
    padding: 0;
    li{

    }
  }
}
.Asset-title{
  font-weight: 500;
  font-size: 2em;
}



// ==========================================================================
// Footer
// ==========================================================================
.Footer {
  padding: 1.5rem 0;
  background: $dark;
  color: $white;
}
.Footer-copy {
  font-size: 0.875rem;
}
.Footer-menu {
  margin: 1rem 0;
  font-weight: 700;

  > li {
    > a {
      color: $white;
    }
  }
}

@include media-breakpoint-up(sm) {
  .Footer-menu {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;


    > li {
      position: relative;

      &:not(:first-child) {
        &:before {
          content: '';
          position: absolute;
          top: 50%;
          left: 0;
          display: block;
          height: 1rem;
          margin-top: -.5rem;
          border-left: 1px solid;
          vertical-align: middle;
        }
      }

      > a {
        padding: .5rem 1rem;
      }
    }
  }
}

@include media-breakpoint-up(md) {
  .Footer-menu {
    margin: 0;
  }
}
